const dripSnippet = (accountId) => {
  const _dcs = {
    account: null
  };
  _dcs.account = accountId;

  const dc = document.createElement('script');
  dc.type = 'text/javascript';
  dc.async = true;
  dc.src = `//tag.getdrip.com/${accountId}.js`;
  const s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(dc, s);
};

export default ({ app }) => {
  if (app.$config?.drip && process.env.VS_ENV === 'prod') {
    dripSnippet(app.$config.drip);
  }
};
