
export const actions = {
  'anchor-scroll': ({ anchorName }) => () => document.getElementsByName(anchorName)?.[0]?.scrollIntoView(),
  'id-scroll': ({ elementId }) => () => document.getElementById(elementId)?.scrollIntoView()
};
export default function (data) {
  const listeners = {};
  const action = data?.action?.[0];

  if (!action) {
    return {
      module: 'SButton',
      ...data
    };
  }
  const actionHandler = action.component?.replace('n-button--', '');
  if (actionHandler && actions[actionHandler]) listeners.click = actions[actionHandler](action);

  if (actionHandler === 'url-link') {
    if (action.link.linktype === 'url') {
      data.link = action.link.anchor && String(action.link.anchor).length ? `${action.link.url}#${action.link.anchor}` : action.link.url;
      data.href = data.link;
    } else {
      data.link = {
        path: `/${action.link.cached_url}/`,
        hash: action.link.anchor && String(action.link.anchor).length ? `#${action.link.anchor}` : null
      };
      data.to = data.link;
    }
  }

  return {
    module: 'SButton',
    ...data,
    slots: {
      default: data.text
    },
    listeners,
    actionHandler
  };
}
