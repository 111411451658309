//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';
import { isProductDisabled, PRODUCT_ONLINE_EVENT_TYPE } from '@/helpers';
import { themeGetters } from '@odyssey/realtainment';
export default {
  name: 'ProductName',
  components: {
    Chip: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/Chip'),
    VideoCameraIcon: () =>
      import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon--video-camera.svg?inline')
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    theme: {
      type: Object,
      default: () => {}
    },
    showSubtitle: {
      type: Boolean,
      required: false,
      default: false
    },
    tag: {
      type: String,
      required: false,
      default: 'h1'
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props) {
    const productSubtitle = computed(() => props.theme?.description || props.subtitle);
    const isArtNightPro = computed(() => props.product?.name?.includes('ArtNight Klassik'));
    return {
      themeGetters,
      productSubtitle,
      isProductDisabled,
      isArtNightPro,
      PRODUCT_ONLINE_EVENT_TYPE
    };
  }
};
