import { Host, HostSocialLink } from '@odyssey/realtainment-api';
import { Context } from '@odyssey/core';
import kebabCase from 'lodash.kebabcase';
import { hack__replaceJpegWithJpg } from '../helpers';

const getName = (host: Host): string => host?.publicName;

const getDescription = (host: Host): string => host.description;

const getSlug = (host: Host): string => kebabCase(`${getName(host)} ${host.id}`);

const getImage = (context: Context, host: Host): string => {
  const { $pimcore } = context.$vsf;
  return `${$pimcore.config.assetsUrl}/upload/image-thumb__${host?.image?.id}__hostMainImage/${hack__replaceJpegWithJpg(host?.image?.filename)}`;
};

const getSocialLinks = (host: Host): Array<HostSocialLink> => {
  const socialDomains = ['facebook', 'instagram', 'twitter'];
  const links = [];
  for (let index = 1; index <= 3; index++) {
    socialDomains.forEach((domain) => {
      if (host[`website${index}`] && host[`website${index}`].includes(`${domain}.com`)) {
        links.push({
          type: domain,
          url: host[`website${index}`]
        });
      }
    });
  }
  return links;
};

const getCity = (host: Host): string => host.user.userCityName;

const getHostFromArray = (hosts: Host[], product: any): Host => hosts.find((h) => product?.hostUuid && h.uuid === product?.hostUuid);

export const hostGetters = {
  getName,
  getSlug,
  getDescription,
  getImage,
  getSocialLinks,
  getCity,
  getHostFromArray
};
