import StoryblokClient from '@odyssey/realtainment-api/src/storyblok/client';

export default function (data, context) {
  return {
    module: 'STile',
    ...data,
    imageMaxHeight: parseInt(data?.imageMaxHeight),
    imageSrc: data?.imageSrc?.filename,
    slots: {
      default: StoryblokClient(context)?.richTextResolver?.render(data?.content)
    }
  };
}
