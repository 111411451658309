import { Theme } from '@odyssey/realtainment-api';
import { Context } from '@odyssey/core';
import { hack__replaceJpegWithJpg, slugify } from '../helpers';

const getName = (theme: Theme): string => theme?.themeName;

const getDescription = (theme: Theme): string => theme.description;

const getCoverImage = (context: Context, theme: Theme): string => {
  const { $pimcore } = context.$vsf;
  return `${$pimcore?.config?.assetsUrl}/upload/image-thumb__${
    theme?.coverImage?.id
  }__themeMainImage/${hack__replaceJpegWithJpg(theme?.coverImage?.filename)}`;
};

const getAdvertisementImage = (context: Context, theme: Theme): string => {
  const { $pimcore } = context.$vsf;
  return `${$pimcore?.config?.assetsUrl}/upload/image-thumb__${
    theme?.advertisementImage?.id
  }__themeMainImage/${hack__replaceJpegWithJpg(theme?.advertisementImage?.filename)}`;
};

const getCoverThumbnailImage = (context: Context, theme: Theme): string => {
  const { $pimcore } = context.$vsf;
  return `${$pimcore?.config?.assetsUrl}/upload/image-thumb__${
    theme?.coverImage?.id
  }__themeThumbnail/${hack__replaceJpegWithJpg(theme?.coverImage?.filename)}`;
};

const getAdditionalImages = (context: Context, theme: Theme): string[] => {
  const { $pimcore } = context.$vsf;

  const keys = ['additionalFirstImage', 'additionalSecondImage', 'additionalThirdImage', 'additionalFourthImage'];

  const images = [];
  for (let i = 0; i < 4; i++) {
    if (theme?.[keys[i]]?.id) {
      images.push(
        `${$pimcore.config.assetsUrl}/upload/image-thumb__${
          theme[keys[i]].id
        }__themeThumbnail/${hack__replaceJpegWithJpg(theme[keys[i]].filename)}`
      );
    }
  }

  return images;
};

const getGalleryImages = (context: Context, theme: Theme): any => {
  const { $pimcore } = context.$vsf;
  const keys = ['additionalFirstImage', 'additionalSecondImage', 'additionalThirdImage', 'additionalFourthImage'];

  const images = [
    {
      src: `${$pimcore.config.assetsUrl}/upload/image-thumb__${
        theme?.coverImage?.id
      }__themeMainImage/${hack__replaceJpegWithJpg(theme?.coverImage?.filename)}`,
      width: theme?.coverImage?.dimensions?.width,
      height: theme?.coverImage?.dimensions?.height
    }
  ];
  for (let i = 0; i < 4; i++) {
    if (theme?.[keys[i]]?.id) {
      images.push({
        src: `${$pimcore.config.assetsUrl}/upload/image-thumb__${
          theme[keys[i]].id
        }__themeMainImage/${hack__replaceJpegWithJpg(theme[keys[i]].filename)}`,
        width: theme?.[keys[i]]?.dimensions?.width,
        height: theme?.[keys[i]]?.dimensions?.height
      });
    }
  }

  return images;
};

const getOrientation = (theme: Theme): string => theme.layout;

const getSlug = (theme: Theme): string => slugify(getName(theme)) + '-malen';

const getThemeFromArray = (themes: Theme[], product: any): Theme =>
  themes.find((t) => product?.eventThemeUuid && t.uuid === product?.eventThemeUuid);

export const themeGetters = {
  getName,
  getCoverImage,
  getAdvertisementImage,
  getAdditionalImages,
  getOrientation,
  getDescription,
  getGalleryImages,
  getSlug,
  getThemeFromArray,
  getCoverThumbnailImage
};
