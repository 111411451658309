//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { onSSR } from '@odyssey/core';
import { useCart, formatGtm } from '@odyssey/realtainment';
import { onMounted, useContext, computed } from '@nuxtjs/composition-api';
export default {
  name: 'SplitScreen',
  components: {
    OfflineBadge: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/OfflineBadge'),
    Notification: () =>
      import(
        /* webpackChunkName: "chunk-ui-molecules-notifications" */ '@/components/UI/molecules/notifications/Notification'
      ),
    CaretDownIcon: () =>
      import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon--caret-down.svg?inline'),
    NuxtTransformImage: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/NuxtTransformImage')
  },
  setup() {
    const { load: loadCart } = useCart();
    const { $gtm, $config, route, i18n } = useContext();
    const isPaymentPage = computed(
      () => route.value?.path === '/payment-error/' || route.value?.path.includes('payment-processing')
    );
    const url = computed(() => {
      switch (route.value?.path) {
        case '/checkout/':
          return '/motiv';
        case '/gruppenevents/konfigurator/':
          return '/gruppenevents';
        default:
          return '/';
      }
    });
    onSSR(async () => {
      await loadCart();
    });
    onMounted(() => {
      $gtm.push(
        formatGtm('all/PAGE_LOAD', {
          info: {
            name: document?.title?.toLowerCase(),
            url: $config.server.url + route.value.fullPath,
            path: route.path,
            referrer: document?.referrer || null,
            environment: $config.server.isProduction ? 'production' : 'staging'
          },
          category: {
            pageType: route.value.meta.pageType
          },
          attributes: {
            country: i18n?.defaultCountry?.toLowerCase(),
            language: i18n?.defaultLanguage?.toLowerCase()
          }
        })
      );
    });

    return {
      url,
      isPaymentPage
    };
  },
  head() {
    return {
      htmlAttrs: {
        class: 'checkout-layout'
      }
    };
  }
};
