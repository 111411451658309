import { extend, localize } from 'vee-validate';
import { required, email, min, min_value, alpha_num, max_value } from 'vee-validate/dist/rules';

export default ({ app }) => {
  const i18n = app.i18n;
  const dictionary = {
    en: {
      messages: {
        required: () => i18n.t('validations.required'),
        email: () => i18n.t('validations.email'),
        min: (key, { length }) => i18n.t('validations.min_length', { min: length }),
        min_value: (key, { length }) => i18n.t('validations.min_length', { min: length }),
        isValidCity: () => i18n.t('form.validations.valid_city'),
        isValidPostcode: () => i18n.t('form.validations.valid_postcode'),
        isValidPhoneNumber: () => i18n.t('form.validations.valid_phonenumber'),
        numeric: () => i18n.t('form.validations.numeric')
      },
      fields: {
        participants: {
          max_value: i18n.t('group_events.details.error_max_participants', {
            link: '<a href="mailto:mail@groupnight.de?subject=Group Booking"><u>' + i18n.t('common.here') + '</u></a>'
          })
        }
      }
    }
  };

  // Install validation rules.
  extend('required', required);
  extend('email', email);
  extend('min', min);
  extend('min_value', min_value);
  extend('max_value', max_value);
  extend('alpha_num', alpha_num);
  extend('isValidPostcode', (value) => value.length >= 3);
  extend('isValidPhoneNumber', (value) => {
    if (value && Object.keys(value).length > 0 && Object.getPrototypeOf(value) === Object.prototype) {
      // prevent error state on init
      if (value.countryCode === undefined && value.formatted.length === 0) {
        return true;
      }
      return value.valid;
    }

    const check = value.match(/([\d]+){10,}\)?([\d]+)/g);

    return check !== null;
  });
  extend('isValidCity', (value) => {
    if (typeof value === 'undefined' || value === null || value === '') {
      return true;
    }
    return /^[A-Za-zÀ-ÖØ-öø-Ÿß-ɏḂ-ỳͰ-ϿЀ-ӿԱ-֏’'.()!?– -]*$/.test(value);
  });
  extend('numeric', (value) => value.toString().match(/^\d+$/));
  localize(dictionary);
};
