import { GTMReturn, GTMInput } from '../types';
export const quantityOpen = (): GTMReturn => ({
  event: 'quantity_open',
  eventInfo: {
    detail: 'expand quantity dropdown'
  },
  category: {
    primaryCategory: 'interaction'
  }
});

export const quantitySelect = (payload: GTMInput): GTMReturn => ({
  event: 'quantity_select',
  eventInfo: {
    detail: 'select quantity'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: payload
});

export const eventLocationView = (payload: GTMInput): GTMReturn => ({
  event: 'event_location_view',
  eventInfo: {
    detail: 'view event location on map'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: payload.attributes
});

export const eventView = (payload: GTMInput): GTMReturn => ({
  event: 'event_view',
  eventInfo: {
    detail: 'view an event detail page'
  },
  category: {
    primaryCategory: 'ecommerce'
  },
  attributes: {
    currency: payload.currency
  },
  items: payload.items,
  _clear: true
});

export const motivView = (payload: GTMInput): GTMReturn => ({
  event: 'motiv_view',
  eventInfo: {
    detail: 'view a motiv detail page'
  },
  category: {
    primaryCategory: 'interaction'
  },
  items: payload.items,
  _clear: true
});

export const moreDatesSelect = (payload: GTMInput): GTMReturn => {
  const start = Date.now();
  const end = Date.parse(payload.date);

  const dateDiff = Math.ceil((end - start) / 86400000);

  return {
    event: 'more_dates_select',
    eventInfo: {
      detail: 'choose alternate event date'
    },
    category: {
      primaryCategory: 'interaction'
    },
    attributes: {
      dateSelected: payload.date,
      daysFromToday: dateDiff
    }
  };
};

export const moreDatesOpen = (payload: GTMInput): GTMReturn => ({
  event: 'more_dates_open',
  eventInfo: {
    detail: 'view alternate event dates'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: {
    dateDisplayed: payload.date
  }
});

export const addToCart = (payload: GTMInput): GTMReturn => ({
  event: 'add_to_cart',
  eventInfo: {
    detail: 'add to cart'
  },
  category: {
    primaryCategory: 'ecommerce'
  },
  attributes: {
    cart_id: payload.cart_id,
    currency: payload.currency
  },
  items: payload.items,
  _clear: true
});
