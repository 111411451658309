export default function ({ _uid, _editable, tabs }) {
  const tabsData = tabs
    ?.map(({ _uid, label, component, eventGrid, filters, showSoldOutEvents }) => {
      if (filters.multiFilters) {
        Object.keys(filters.multiFilters).forEach(key => {
          if (!filters.multiFilters[key].length) {
            filters.multiFilters[key] = null;
          }
        });
      }

      return {
        _uid,
        label,
        component,
        filters,
        showSoldOutEvents,
        limit: parseInt(eventGrid?.[0]?.showProducts, 10 || 18),
        categoryId: eventGrid?.[0]?.category?.categoryId,
        categorySlug: eventGrid?.[0]?.categorySlug?.slug,
        showTitle: eventGrid?.[0]?.showTitle
      };
    });
  return {
    module: 'STabbedEventGrid',
    _uid,
    _editable,
    tabs: tabsData
  };
}
