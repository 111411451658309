import { GTMReturn, GTMInput } from '../types';
export const eventShowMore = (payload: GTMInput): GTMReturn => ({
  event: 'events_show_more',
  eventInfo: {
    detail: 'show more events click'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: {
    location: payload.location
  }
});

export const eventImpression = (payload: GTMInput): GTMReturn => ({
  event: 'events_impression',
  eventInfo: {
    detail: 'view list of events'
  },
  category: {
    primaryCategory: 'ecommerce'
  },
  attributes: {
    location: payload.location,
    currency: payload.currency
  },
  items: payload.items,
  _clear: true
});

export const eventSelect = (payload: GTMInput): GTMReturn => ({
  event: 'event_select',
  eventInfo: {
    detail: 'select an event'
  },
  category: {
    primaryCategory: 'ecommerce'
  },
  attributes: {
    location: 'listing',
    currency: payload.currency
  },
  items: payload.items,
  _clear: true
});

export const filterOpen = (payload: GTMInput): GTMReturn => ({
  event: 'filter_open',
  eventInfo: {
    detail: 'open a filter'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: {
    filterName: payload.filter
  }
});

export const filterApply = (payload: GTMInput): GTMReturn => ({
  event: 'filter_select',
  eventInfo: {
    detail: 'select a filter'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: {
    filterName: payload.filter,
    optionSelected: payload.selected
  }
});

export const filterReset = (payload: GTMInput): GTMReturn => ({
  event: 'filter_reset',
  eventInfo: {
    detail: 'reset a filter'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: {
    filterName: payload.filterName
  }
});

export const citySelectionOpen = (payload: GTMInput): GTMReturn => ({
  event: 'city_selection_open',
  eventInfo: {
    detail: 'open city selection'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: {
    location: payload.location
  }
});

export const citySelectionInputStart = (payload: GTMInput): GTMReturn => ({
  event: 'city_selection_input_start',
  eventInfo: {
    detail: 'begin typing in city selection'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: {
    location: payload.location
  }
});

export const citySelectionSubmit = (payload: GTMInput): GTMReturn => ({
  event: 'city_selection_submit',
  eventInfo: {
    detail: 'submit city selection option'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: {
    location: payload.location,
    selection: payload.selection
  }
});
