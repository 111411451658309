import cloneDeep from 'lodash.clonedeep';
import { useProductFilters } from '../useProductFilters';
import { useTheme } from '../useTheme';
import { useThemeCategory, themeCategoryGetters } from '../useThemeCategory';
import { useHost, hostGetters } from '../useHost';
import { useVenue, venueGetters } from '../useVenue';
import { PRODUCT_ONLINE_EVENT_TYPE, PRODUCT_EVENT_TYPE, PRODUCT_MATERIAL_TYPE, PRODUCT_BUNDLE_TYPE, PRODUCT_GIFTCARD_TYPE, PRODUCT_MOTIV_TYPE } from '../index';
import { GtmItem } from '../types';

function formatPrice(price: number): string {
  return price.toFixed(2).toString();
}

function getPrice(item): number {
  return item.total ? item.total : item.price;
}

export const useGTM = (): any => {
  const { SKILL_NAMES } = useProductFilters();
  const { result: themes } = useTheme();
  const { result: themeCategories } = useThemeCategory();
  const { result: venues } = useVenue();
  const { result: hosts } = useHost();

  const prepareGTagItems = (context, itm, list_id = null, list_name = null, index = null) => {
    if (!itm) return;
    const itemFormatters = {
      giftbox: ({ item }) => ({
        item_name: item.name?.toLowerCase(),
        item_id: item.sku,
        price: formatPrice(getPrice(item.price)),
        item_brand: 'giftbox',
        item_category: 'giftbox',
        item_category_2: 'physical product',
        item_category_3: item.name?.toLowerCase(),
        item_category_4: 'n/a giftbox',
        item_variant: null,
        item_list_name: 'giftbox',
        quantity: item.qty
      }),
      [PRODUCT_EVENT_TYPE]: ({ item, list_id = null, list_name = null, index = null }) => {

        const host = hosts.value.find((h) => h.uuid === item.hostUuid);
        const venue = venues.value.find((v) => v.uuid === item.venueUuid);
        const theme = themes.value.find((t) => t.uuid === item.eventThemeUuid);
        const themeCategory = themeCategories.value.find((t) => item.themeCategoryUuid?.includes(t.uuid));

        // Fill this with cart content
        const ret = {
          item_city: item.categorySlug?.toLowerCase() === 'online-events' ? 'Livestream' : item.event_city,
          item_date: item.event_date?.string,
          item_name: item.name?.toLowerCase(),
          item_id: item.sku,
          price: formatPrice(getPrice(item) / 100),
          item_brand: host ? hostGetters.getName(host)?.toLowerCase() : 'no host',
          item_category: item.categorySlug?.toLowerCase(),
          item_category_2: item.type_id === PRODUCT_ONLINE_EVENT_TYPE ? 'online' : (venue ? venueGetters.getName(venue)?.toLowerCase() : 'location ausstehend'),
          item_category_3: themeCategory ? themeCategoryGetters.getName(themeCategory)?.toLowerCase() : 'no theme category',
          item_category_4: SKILL_NAMES[parseInt(item?.skill)]?.toLowerCase() || 'no skill level'
        } as GtmItem;

        if (item.qty) ret.quantity = item.qty.toString();
        if (list_id) ret.item_list_id = list_id.toLowerCase();
        if (list_name) ret.item_list_name = list_name.toLowerCase();
        if (item.event_date) ret.item_variant = item.event_date.day || item.event_date.string; // Date
        if (index !== null) ret.index = (index + 1).toString();
        if (theme?.segmentation_label) ret.item_category_5 = theme.segmentation_label;

        return ret;
      },
      [PRODUCT_BUNDLE_TYPE]: ({ item, config }) => ({
        item_name: item.name?.toLowerCase(),
        item_id: item.sku,
        price: formatPrice(getPrice(item) / 100),
        item_brand: `multi-ticket ${config.seo.defaultTitle.toLowerCase()}`,
        item_category: 'personalized giftcard',
        item_category_2: 'multi-ticket',
        item_category_3: item.name?.toLowerCase(),
        item_category_4: `multi-ticket ${(item.price / 100)}`,
        item_variant: null,
        item_list_name: 'personalised gift cards',
        quantity: item.qty
      }),
      [PRODUCT_GIFTCARD_TYPE]: ({ item, config, i18n }) => {
        const brand = config.seo.defaultTitle.toLowerCase();
        const voucherOptions = (i18n.t(`voucher.${brand}.voucher_personalisation_options`) || [] as any);
        const voucherSelectedText = (item?.product_option?.header || item?.custom_header);
        // Match option from translations based on the selected voucher text
        const voucherSelectedOption = voucherOptions.find(o => o.text.includes(voucherSelectedText));
        // If there's `voucherSelectedTextz` try to use translatin option `name` otherwise fallback personalized text
        const selectedOption = voucherSelectedText ? voucherSelectedOption?.name || 'personalized text' : 'unausgefullt (empty)';

        return {
          item_name: item.name?.toLowerCase(),
          item_id: item.sku,
          price: formatPrice(getPrice(item) / 100),
          item_brand: `gutschein ${brand}`,
          item_category: 'personalized giftcard',
          item_category_2: 'gutschein',
          item_category_3: selectedOption?.toLowerCase(),
          item_category_4: `giftcard ${(item.price / 100)}`,
          item_variant: null,
          item_list_name: 'personalised gift cards',
          quantity: item.qty
        };
      },
      [PRODUCT_MATERIAL_TYPE]: ({ item, config }) => ({
        item_name: item.name?.toLowerCase(),
        item_id: item.sku,
        price: formatPrice(getPrice(item) / 100),
        item_brand: `material kit ${config.seo.defaultTitle.toLowerCase()}`,
        item_category: 'material kit',
        item_category_2: 'physical product',
        item_category_3: item.name?.toLowerCase(),
        item_category_4: 'n/a material kit',
        item_variant: null,
        item_list_name: 'material kits',
        quantity: item.qty
      }),

      [PRODUCT_MOTIV_TYPE]: ({ item, config }) => {
        const slug = window.location.pathname.replace('/motiv/', '').replace('/', '');
        const ret = {
          item_name: item.themeName?.toLowerCase(),
          item_id: item.id,
          item_duration: item.duration,
          item_variant: slug,
          item_brand: `motiv ${config.seo.defaultTitle.toLowerCase()}`,
          item_category: item.themeName?.toLowerCase(),
          item_category_2: item.categories[0].name,
          item_list_name: 'motiv view'
        };
        return ret;
      }
    };

    // Clone item to make sure all the computed props are final
    const item = cloneDeep(itm);
    let itemType = item.typeId || item.product_type;

    if ([PRODUCT_ONLINE_EVENT_TYPE, PRODUCT_EVENT_TYPE].includes(itemType)) itemType = PRODUCT_EVENT_TYPE;

    if (item.category_slug === 'giftbox') itemType = 'giftbox';

    if (item.__typename === 'object_Theme') itemType = 'object_Theme';

    const { $config, i18n } = context;

    if (itemFormatters[itemType]) {
      const formatter = itemFormatters[itemType];
      try {
        const result = formatter({ item, list_id, list_name, index, config: $config, i18n });
        return result;
      } catch (error) {

        console.error(item, 'prepareGTagItems', error);
        return {};
      }
    }

    return {};
  };

  return {
    prepareGTagItems
  };
};
