import { Context } from '@odyssey/core';
import SBClient from 'storyblok-js-client';
import RichTextSchema from './RichTextSchema';
export let storyblokClient = null;

export default function StoryblokClient(context: Context): any {
  if (storyblokClient) return storyblokClient;
  const client = storyblokClient || new SBClient({
    accessToken: context.config.token,
    cache: {
      clear: 'auto',
      type: 'memory'
    },
    https: true,
    timeout: 100000,
    richTextSchema: RichTextSchema
  });

  storyblokClient = client;
  return storyblokClient;
}
