import Vue from 'vue';
import { useContext } from '@nuxtjs/composition-api';
const entities = {
  amp: '&',
  apos: '\'',
  lt: '<',
  gt: '>',
  quot: '"',
  nbsp: '\xa0'
};
const entityPattern = /&([a-z]+);/ig;

function decodeHTMLEntities(text) {
  if (!text) return text;
  return text.replace(entityPattern, (match, entity) => {
    entity = entity.toLowerCase();
    if (entities[entity]) return entities[entity];
    return match;
  });
}

Vue.filter('htmlDecode', (val) => decodeHTMLEntities(val));

Vue.filter('currency', (val) => {
  val /= 100;
  const { i18n } = useContext();
  return i18n.n(val, 'currency');
  // return i18n.n(val, val % 1 !== 0 ? 'currency' : 'currencyNoCents');
});

Vue.filter('currencyNoCents', (val) => {
  val /= 100;
  const { i18n } = useContext();
  return i18n.n(val, 'currencyNoCents');
});
