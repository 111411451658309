import {
  Context,
  Logger,
  useCategoryFactory,
  UseCategoryFactoryParams
} from '@odyssey/core';
import { Category } from '../types';

const params: UseCategoryFactoryParams<Category, any> = {
  categorySearch: async (context: Context, { customQuery, ...searchParams }) => {
    try {
      const category = await context.$sylius.api.getCategory(searchParams, customQuery);

      return category.filter((obj) => obj.isActive).sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    } catch (e) {
      Logger.error(e);
    }
    return [];
  }
};

export const useCategory = useCategoryFactory<Category, any>(params);
