//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';
const BASE_CLASS = 'event-description';
const SUPPORTED_CSS_FLAGS = ['is-paddingless'];

export default {
  name: 'EventDescription',
  props: {
    product: {
      type: Object,
      required: true
    },
    cssFlags: {
      type: Array,
      required: false,
      default: () => [],
      validator: (flags) =>
        flags.filter((f) => SUPPORTED_CSS_FLAGS.includes(f)).length ===
        flags.length
    }
  },
  setup(props) {
    const containerClasses = computed(() => [
      BASE_CLASS,
      ...props.cssFlags.map((f) => `${BASE_CLASS}--${f}`)
    ]);

    return {
      containerClasses
    };
  }
};
