import StoryblokClient from '@odyssey/realtainment-api/src/storyblok/client';

export default function ({ preText, highlightedText, afterText, _editable, _uid }, context) {
  return {
    module: 'SHeaderSlogan',
    component: 's-header-slogan',
    _uid,
    _editable,
    preText: StoryblokClient(context)?.richTextResolver?.render(preText),
    highlightedText: StoryblokClient(context)?.richTextResolver?.render(highlightedText),
    afterText: StoryblokClient(context)?.richTextResolver?.render(afterText)
  };
}
