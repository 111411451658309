import { Venue } from '@odyssey/realtainment-api';
import { Context } from '@odyssey/core';
import { hack__replaceJpegWithJpg } from '../helpers';
import kebabCase from 'lodash.kebabcase';

const getName = (venue: Venue): string => venue.name;

const getSlug = (venue: Venue): string => kebabCase(`${getName(venue)} ${venue.id}`);

const getDescription = (venue: Venue): string => venue.publicDescription;

const getAddress = (venue: Venue): string => `${venue.venueStreet} ${venue.venueBuildingNumber}, ${venue.city.name} ${venue.venuePostcode}`;

const getImage = (context: Context, venue: Venue): string => {
  const { $pimcore } = context.$vsf;
  return `${$pimcore.config.assetsUrl}/upload/image-thumb__${venue?.venueImage?.id}__venueMainImage/${hack__replaceJpegWithJpg(venue?.venueImage?.filename)}`;
};

const getMapLink = (venue: Venue): string => {
  const encodedAddress = encodeURI(`${getName(venue)}, ${getAddress(venue)}`);
  if (typeof navigator !== 'undefined') {
    const isSafari = Boolean(navigator.userAgent.match(/Version\/[\d.]+.*Safari/));
    const iOS =
      // eslint-disable-next-line dot-notation
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window['MSStream'];

    if (isSafari && iOS) {
      return 'http://maps.apple.com/?q=' + encodedAddress;
    }
  }
  return 'https://maps.google.com/?q=' + encodedAddress;
};

const getVenueFromArray = (venues: Venue[], product: any): Venue => venues.find((v) => product?.venueUuid && v.uuid === product?.venueUuid);

export const venueGetters = {
  getName,
  getSlug,
  getDescription,
  getAddress,
  getImage,
  getMapLink,
  getVenueFromArray
};
