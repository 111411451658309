import { Logger } from '@odyssey/core';

export default async ({ app: { context }, $vsf }) => {
  try {
    const { route, redirect } = context;
    const redirects = await $vsf.$storyblok.api.getStoryblokRedirects();

    let matchingRedirect = false;
    if (Array.isArray(redirects)) {
      matchingRedirect = redirects.find(rule => {
        const isMatchingPath = rule.source === route.path;
        const isMatchingPathRegex = rule.isRegex && RegExp(rule.source).test(route.path);

        return isMatchingPath || isMatchingPathRegex;
      });
    }

    if (matchingRedirect) {
      Logger.debug(`[Redirect] Redirecting ${matchingRedirect.source} to ${matchingRedirect.target}`);
      redirect(parseInt(matchingRedirect.type, 10), matchingRedirect.target);
    }

  } catch (error) {
    Logger.error('[Redirect]', error);
  }

};

