import { parse as parseDate, eachMinuteOfInterval } from 'date-fns';

const getTimestampFromDate = (date) => {
  if (date.length) {
    const timestamp = parseDate(date + ' 00:00', 'yyyy-MM-dd HH:mm', new Date()).getTime();
    if (timestamp) return timestamp / 1000;
  }
  return null;
};

const getDateFromTimeString = (time) => {
  const [hours, minutes] = time.split(':');
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

const getTimeRange = (time_from, time_to) => {
  if (!time_from.length && !time_to.length) return [];
  let date_start = false;
  let date_end = false;
  if (time_from.length) date_start = getDateFromTimeString(time_from);
  else date_start = getDateFromTimeString('00:00');
  if (time_to.length) date_end = getDateFromTimeString(time_to);
  else date_end = getDateFromTimeString('23:59');
  return eachMinuteOfInterval({ start: date_start, end: date_end }, { step: 15 }).map(time => {
    let minutes = time.getMinutes();
    if (minutes === 0) minutes = '00';
    return `${time.getHours()}:${minutes}`;
  });
};

export default function ({ _uid, _editable, filters, title, mode, showTitle, limit, showSoldOutEvents, showSalesStoppedEvents, showCitySelect, hideLoadMoreButton, removeDuplicateThemes }) {
  const gridFilters = filters.multiFilters || filters.filters;

  if (filters.weekdayFilters && filters.weekdayFilters?.length) {
    gridFilters.push({
      name: 'event_date.weekday_in_iso_8601',
      value: filters.weekdayFilters,
      operator: 'in'
    });
  }

  const timeRange = getTimeRange(filters.dateFilters.time_from, filters.dateFilters.time_to);
  if (timeRange.length) {
    gridFilters.push({
      name: 'event_date.start_time',
      value: timeRange,
      operator: 'in'
    });
  }

  const dateFromTimestamp = getTimestampFromDate(filters.dateFilters.date_from);
  if (dateFromTimestamp) {
    gridFilters.push({
      name: 'event_date.timestamp',
      value: dateFromTimestamp,
      operator: 'gte'
    });
  }

  const dateToTimestamp = getTimestampFromDate(filters.dateFilters.date_to);
  if (dateToTimestamp) {
    gridFilters.push({
      name: 'event_date.timestamp',
      value: dateToTimestamp,
      operator: 'lte'
    });
  }

  return {
    module: 'SFilteredEventGrid',
    _uid,
    _editable,
    gridKey: `storyblok-${_uid}`,
    gridFilters,
    limit: filters.limit ?? parseInt(limit),
    title,
    mode,
    showTitle,
    showSoldOutEvents,
    showSalesStoppedEvents,
    showCitySelect,
    hideLoadMoreButton,
    removeDuplicateThemes
  };
}
