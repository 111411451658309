import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext, Logger } from '@odyssey/core';
import { removeDuplicatesByKey, prepareFilterArgument } from '../helpers';
export * from './getters';
export const useThemeCategory = (): any => {
  const { $pimcore } = useVSFContext();
  const data = sharedRef([], 'useThemeCategory--data');
  const loading = sharedRef(null, 'useThemeCategory--loading');
  const error = sharedRef(null, 'useThemeCategory--error');

  const addToData = (theme_categories, key = 'uuid') => {
    data.value = removeDuplicatesByKey([...data.value, ...theme_categories], key);
  };

  const search = async (themeCategoryUuid: string | Array<string>): Promise<void> => {
    const filter = themeCategoryUuid ? prepareFilterArgument('uuid', themeCategoryUuid, data.value) : null;

    if (filter && filter.length === 0) return;

    try {
      loading.value = true;
      const themeCategories = await $pimcore.api.getThemeCategories({ filter });
      data.value = removeDuplicatesByKey([...data.value, ...themeCategories], 'uuid');
      error.value = null;
    } catch (err) {
      error.value = err;
      Logger.error('useThemeCategory/search', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    search,
    addToData,
    loading,
    error,
    result: computed(() => data.value)
  };
};
