import { computed } from '@nuxtjs/composition-api';
import { sharedRef, Logger, useVSFContext } from '@odyssey/core';
import { useCart } from '../useCart';

export const useCheckout = (): any => {
  const checkoutLoading = sharedRef(false, 'useCheckout--loading');
  const checkoutConfirmation = sharedRef(null, 'useCheckout--confirmation');
  const countries = sharedRef([], 'useCheckout-countries');
  const shippingMethods = sharedRef([], 'useCheckout-shippingMethods');
  const shippingMethodsLoading = sharedRef(false, 'useCheckout-shippingMethods--loading');
  const paymentMethods = sharedRef([], 'useCheckout-paymentMethods');
  const paymentMethodsLoading = sharedRef(false, 'useCheckout-paymentMethods--loading');
  const error = sharedRef(null, 'useCheckout-error');
  const order = sharedRef(null, 'useCheckout-order');
  const orderResponse = sharedRef(null, 'useCheckout-orderResponse');
  const personalDetails = sharedRef(
    {
      notes: undefined,
      firstName: undefined,
      lastName: undefined,
      company: undefined,
      countryId: 'DE',
      streetAddress: undefined,
      apartmentNumber: undefined,
      city: undefined,
      email: undefined,
      state: undefined,
      region_id: 0,
      zipCode: undefined,
      phoneNumber: undefined,
      taxId: undefined,
      paymentMethod: 'mollie-creditcard'
    },
    'useCheckout-checkoutPersonalDetails'
  );

  const { $rest, $sylius } = useVSFContext();
  const { cart, load: loadCart } = useCart();
  // user auth token
  const token = '';

  const loadCountries = async (): Promise<void> => {
    try {
      const result = await $sylius.api.getCountries();
      countries.value = result;
    } catch (err) {
      Logger.error('useCheckout/loadCountries', err);
    }
  };

  const loadShippingMethods = async ({ country_id }: { country_id: string }): Promise<void> => {
    const apiState = $sylius.config.state;
    const cartId = apiState.getCartId();

    if (cartId) {
      try {
        shippingMethodsLoading.value = true;
        const { result } = await $rest.api.getShippingMethods({ cartId, token, country_id });
        shippingMethods.value = result;
      } catch (err) {
        error.value = err.response.data;
        Logger.error('useCheckout/loadShippingMethods', err);
      } finally {
        shippingMethodsLoading.value = false;
      }
    }
  };

  const loadPaymentMethods = async (): Promise<void> => {
    const apiState = $sylius.config.state;
    const cartId = apiState.getCartId();
    if (cartId) {
      try {
        paymentMethodsLoading.value = true;
        const { result } = await $rest.api.getPaymentMethods({ cartId, token });

        // filter out ApplePay on unsupported devices
        paymentMethods.value = result.filter((gateway) => {
          if (gateway.code.includes('applepay')) {
            return 'ApplePaySession' in window;
          }
          return gateway.code !== 'mollie_wc_gateway_mistercash' && gateway.code !== 'free_checkout';
        });
      } catch (err) {
        Logger.error('useCheckout/loadPaymentMethods', err);
      } finally {
        paymentMethodsLoading.value = false;
      }
    }
  };

  const createOrder = async (): Promise<any> => {
    checkoutLoading.value = true;

    let street = personalDetails.value.streetAddress;
    let postcode = personalDetails.value.zipCode;
    if (personalDetails.value.apartmentNumber) street += ' ' + personalDetails.value.apartmentNumber;
    if (!street) {
      street = 'N/A';
      postcode = 'N/A';
    }

    const address = {
      firstname: personalDetails.value.firstName,
      lastname: personalDetails.value.lastName,
      street: [street],
      city: personalDetails.value.city,
      postcode,
      countryId: personalDetails.value.countryId,
      company: undefined
    };

    if (personalDetails.value.company) {
      // eslint-disable-next-line
      address['company'] = personalDetails.value.company;
    }

    const payload = {
      cart_id: $sylius.config.state.getCartId(),
      addressInformation: {
        shippingAddress: address,
        billingAddress: address
      },
      giftcards: [],
      coupons: [],
      notes: personalDetails.value.notes,
      phoneNumber: personalDetails.value.phoneNumber,
      email: personalDetails.value.email,
      payment_method_code: personalDetails.value.paymentMethod,
      shipping_method_code: '',
      shipping_carrier_code: '',
      products: cart.value.items.map(({ qty, sku, price }) => ({
        qty,
        sku,
        price
      }))
    };

    try {
      const response = await $rest.api.createOrder({ payload });
      orderResponse.value = response;
      order.value = response.result.vsfOrder;
      $sylius.config.state.setCartId(null);
      await loadCart();
      return response;
    } catch (err) {
      error.value = err.response.data;
      Logger.error('useCheckout/createOrder', err);
      throw err.response.data;
    } finally {
      checkoutLoading.value = false;
    }
  };

  const confirmOrder = async ({ cartId, purpose, isRetry = false }): Promise<any> => {
    if (!window || !window?.localStorage) return;

    // Cache last order in local storage
    const lsOrder = localStorage.getItem(`TY/last-order/${cartId}`) || '{}';
    const parsedOrder = JSON.parse(lsOrder);
    const isMatchingOrder = parsedOrder?.cart_id === cartId;
    if (isMatchingOrder && !isRetry) {
      checkoutConfirmation.value = parsedOrder;
      return {};
    }

    const response = await $rest.api.confirmOrder({
      cart_id: cartId,
      purpose
    });

    checkoutConfirmation.value = response.result.vsfOrder;

    localStorage.setItem(`TY/last-order/${cartId}`, JSON.stringify(response.result.vsfOrder));
    return response;
  };

  const clearOrder = () => {
    order.value = null;
  };

  return {
    checkoutLoading,
    countries: computed(() => countries.value),
    error: computed(() => error.value),
    shippingMethods: computed(() => shippingMethods.value),
    shippingMethodsLoading,
    paymentMethods: computed(() => paymentMethods.value),
    personalDetails,
    paymentMethodsLoading,
    checkoutConfirmation,
    loadShippingMethods,
    loadPaymentMethods,
    loadCountries,
    createOrder,
    confirmOrder,
    clearOrder,
    order,
    orderResponse
  };
};
