import { GTMReturn, GTMInput } from '../types';
export const addPaymentInfo = (payload: GTMInput): GTMReturn => ({
  event: 'add_payment_info',
  eventInfo: {
    detail: 'add payment information to order'
  },
  category: {
    primaryCategory: 'ecommerce'
  },
  attributes: {
    cart_id: payload.cart_id,
    payment_type: payload.payment_method,
    currency: payload.currency,
    corporate_order: payload.corporate_order
  },
  items: payload.items,
  _clear: true
});

export const purchase = (payload: GTMInput): GTMReturn => ({
  event: 'purchase',
  eventInfo: {
    detail: 'purchase made'
  },
  category: {
    primaryCategory: 'ecommerce'
  },
  attributes: {
    payment_type: payload.payment_type,
    corporate_order: payload.corporate_order,
    email: payload.email,
    first_name: payload.first_name
  },
  transaction: payload.transaction,
  items: payload.items,
  _clear: true
});

export const purchaseSubmitError = (payload: GTMInput): GTMReturn => ({
  event: 'purchase_submit_error',
  eventInfo: {
    detail: 'failed purchase submission'
  },
  category: {
    primaryCategory: 'error'
  },
  attributes: {
    cart_id: payload.cart_id,
    cart_value: payload.cart_value,
    currency: payload.currency,
    corporate_order: payload.corporate_order,
    errorType: payload.errorType,
    errorMessage: payload.errorMessage
  }
});
export const purchaseSubmit = (payload: GTMInput): GTMReturn => ({
  event: 'purchase_submit',
  eventInfo: {
    detail: 'continue to purchase'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: {
    cart_id: payload.cart_id,
    cart_value: payload.cart_value,
    currency: payload.currency,
    corporate_order: payload.corporate_order
  }
});

export const beginCheckout = (payload: GTMInput): GTMReturn => ({
  event: 'begin_checkout',
  eventInfo: {
    detail: 'begin checkout'
  },
  category: {
    primaryCategory: 'ecommerce'
  },
  attributes: {
    cart_id: payload.cart_id,
    location: payload.location,
    currency: payload.currency
  },
  items: payload.items,
  _clear: true
});

export const removeFromCart = (payload: GTMInput): GTMReturn => ({
  event: 'remove_from_cart',
  eventInfo: {
    detail: 'remove from cart'
  },
  category: {
    primaryCategory: 'ecommerce'
  },
  attributes: {
    cart_id: payload.cart_id,
    currency: payload.currency,
    location: payload.location
  },
  items: payload.items,
  _clear: true
});

export const addCouponError = (payload: GTMInput): GTMReturn => ({
  event: 'voucher_code_error',
  eventInfo: {
    detail: 'error with voucher code'
  },
  category: {
    primaryCategory: 'error'
  },
  attributes: payload
});

export const addCouponSuccess = (payload: GTMInput): GTMReturn => ({
  event: 'voucher_code_success',
  eventInfo: {
    detail: 'voucher code was successful'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: {
    coupon: payload?.code || payload?.couponCode
  }
});

export const addCouponSubmit = (payload: GTMInput): GTMReturn => ({
  event: 'voucher_code_submit',
  eventInfo: {
    detail: 'submit voucher code'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: {
    coupon: payload?.code || payload?.couponCode
  }
});

export const addCouponOpen = (): GTMReturn => ({
  event: 'voucher_code_open',
  eventInfo: {
    detail: 'open voucher code input box'
  },
  category: {
    primaryCategory: 'interaction'
  }
});

export const corporateOrderToggle = (payload: GTMInput): GTMReturn => ({
  event: 'corporate_order_toggle',
  eventInfo: {
    detail: 'corporate order toggled on or off'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: {
    corporate_order: payload.value
  }
});

export const purchaseError = (): GTMReturn => ({
  event: 'purchase_error',
  eventInfo: {
    detail: 'payment error'
  },
  category: {
    primaryCategory: 'ecommerce'
  }
});

export const purchasePaymentFailed = (payload: GTMInput): GTMReturn => ({
  event: 'purchase_payment_failed',
  eventInfo: {
    detail: 'payment failed'
  },
  category: {
    primaryCategory: 'ecommerce'
  },
  attributes: {
    location: payload.location,
    payment_type: payload.payment_type,
    email: payload.email,
    first_name: payload.first_name,
    errorMessage: payload.errorMessage
  },
  transaction: payload.transaction
});

export const purchasePaymentPending = (payload: GTMInput): GTMReturn => ({
  event: 'purchase_payment_pending',
  eventInfo: {
    detail: 'payment pending'
  },
  category: {
    primaryCategory: 'ecommerce'
  },
  attributes: {
    location: payload.location,
    payment_type: payload.payment_type,
    email: payload.email,
    first_name: payload.first_name
  },
  transaction: payload.transaction,
  items: payload.items,
  _clear: true
});

export const returnToCartClicked = (payload: GTMInput): GTMReturn => ({
  event: 'return_to_cart_clicked',
  eventInfo: {
    detail: 'return to cart clicked'
  },
  category: {
    primaryCategory: 'ecommerce'
  },
  attributes: {
    location: payload.location,
    payment_type: payload.payment_type,
    email: payload.email,
    first_name: payload.first_name
  },
  transaction: payload.transaction,
  _clear: true
});
