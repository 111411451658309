import StoryblokClient from '@odyssey/realtainment-api/src/storyblok/client';

export default function ({ cssFlagsResponsive, _uid, _editable, text, textShadow, textColor, textStyle, id }, context) {
  return {
    module: 'SContent',
    _uid,
    _editable,
    cssFlags: typeof cssFlagsResponsive === 'object' ? cssFlagsResponsive : {},
    textShadow,
    textColor,
    textStyle,
    id,
    slots: {
      default: StoryblokClient(context)?.richTextResolver?.render(text)
    }
  };
}
