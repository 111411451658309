import componentParser from './component-parser';

export default function (
  {
    cssFlags,
    background,
    components,
    columnSizes,
    minHeight,
    maxWidth,
    padding,
    margin,
    borderRadius,
    badge,
    _uid,
    _editable,
    id
  },
  context
) {
  return {
    module: 'SSectionColumn',
    _uid,
    _editable,
    cssFlags,
    background,
    padding,
    margin,
    badge,
    id,
    borderRadius,
    maxWidth: `${maxWidth}px`,
    minHeight: `${minHeight}px`,
    columnSizes: columnSizes?.split(',')?.map((size) => parseInt(size, 10)) || [12, 12, 12],
    components: components?.map((c) => componentParser(c, context)) || []
  };
}
