/* eslint-disable  */

export default {
  common: {
    add: 'Hinzufügen',
    read_more: 'erweitern',
    read_less: 'reduzieren',
    as_seen_on: 'Bekannt aus',
    more_info: 'Mehr Info',
    required_field: 'Pflichtfelder',
    submit: 'Anwenden',
    reset: 'Zurücksetzen',
    search: 'Suche',
    back: 'Zurück',
    save_money: 'Spare',
    more: 'Weitere',
    next: 'Weiter',
    oder: 'oder',
    spare: 'SPARE',
    load_more: 'Mehr laden',
    close: 'Schließen',
    here: 'hier',
    email: 'EMail',
    with: 'Mit',
    soldout: 'Ausverkauft',
    soldout2: 'Ausgebucht',
    all_motifs: 'Alle Motive',
    all: 'Alle',
    location: 'Location',
    welcome_by_AN: 'Willkommen bei ArtNight!',
    select_city_content:
      'Wähle deinen nächstgelegenen Ort, um direkt zu den verfügbaren Malkurse in deiner Stadt zu gelangen!',
    later: 'Später',
    city: 'Stadt',
    view_AN: 'ArtNights anzeigen',
    reset: 'Zurücksetzen'
  },
  online_badge: {
    our: 'Unsere',
    more: 'More',
    online: 'Online'
  },
  menu: {
    online_team_events: 'Online Team Events',
    team_events: 'Gruppen & Firmen',
    team_events2: 'Gruppenevents',
    team_events3: 'Private {brand}s',
    team_events_virtuelle: 'Virtuelle Team Events',
    bachelorette_party: 'Junggesellinnenabschiede',
    about_us: 'Über uns',
    press: 'Presse',
    terms: 'AGB',
    impressum: 'Impressum',
    privacy: 'Datenschutz',
    voucher: 'Gutschein',
    gift_voucher: 'Geschenk-Gutschein',
    subtitle: 'Digitaler Gutschein zum Ausdrucken',
    voucher_as_gift: 'Gutschein verschenken',
    personalised_voucher: 'Personalisierter-Gutschein',
    giftbox: 'Gutscheinbox',
    ticket_rebooking: 'Ticket Umbuchung',
    become_artist: 'Künstler werden',
    become_acryl_artist: 'Acryl Künstler',
    become_handlettering_artist: 'Handlettering Künstler',
    become_host: 'Host werden',
    become_baker: 'Kursleitung werden',
    become_location: 'Location werden',
    help_contact: 'Hilfe & Kontakt',
    jobs: 'Jobs',
    blog: 'Blog',
    faq: 'FAQ',
    contact: 'Kontakt',
    at_home: '{brand}s für zuhause',
    livestream: '{brand} Live Stream',
    live_online: 'Online {brand}',
    live_online2: 'Live Online {brand}',
    online_events: 'Online Malkurs',
    video_tutorials: 'Videokurse',
    baking_tutorials: 'Backkurse',
    material_brand: '{brand} Materialien kaufen',
    material: 'Material',
    email_coach: 'E-Mail Coach: Menschen zeichnen'
  },
  errors: {
    general: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
    message_404: 'Leider können wir die gesuchte Seite nicht finden.'
  },
  category: {
    new_label: 'JETZT NEU',
    material_description:
      'Mit unseren hochwertigen Materialien steht dir nichts mehr im Weg deiner Kreativität freien Lauf zu lassen!',
    live_label: 'Unsere {type} {brand}s',
    motiv_page_title: 'ArtNight: Dein Erlebnis. Dein Kunstwerk. Alle Motive',
    events_page_title: 'Malkurse in deiner Nähe | ArtNight',
    meta_description:
      'Malkurse in {city} erleben: Egal ob Acryl, Abstrakte Kunst, Handlettering oder Intensivkurse. Buche jetzt Deinen Malkurs in {city}.',
    meta_online_description:
      'Erlebe online unter Anleitung von erfahrenen Künstler:innen aufregende Malkurse und Art-Events!',
    meta_title: 'Malkurse in {city} | Malkurse in deiner Nähe | {brand}',
    live_meta_title: 'Malkurse online acryl - {brand}',
    artnight_live_description:
      'Entdecke, wie auf einer ArtNight, den Spaß am Malen, als interaktives Erlebnis live von zu Hause aus!\u2028Unsere Künstler führen dich per Livestream Schritt für Schritt durch dein gewähltes Motiv. Du kannst jederzeit Fragen stellen und dich mit den anderen Teilnehmern austauschen. Für eine Online ArtNight brauchst du eine Leinwand (30x40cm), Acrylfarben und Pinsel. Optional kannst du die Materialien beim Kauf direkt mitbestellen. Nach Kaufabschluss erhältst du einen Link, über den du dich in den Livestream der ArtNight einwählen kannst.',
    search_no_cities: 'Es gibt keine Stadt namens {query}.',
    search_title: 'Stadt eingeben',
    events_in: 'Malkurse in {city}',
    filters: {
      label: 'Filter',
      reset: 'Filter zurücksetzen',
      type: {
        regular: 'Reguläre {brand}',
        regular_text: 'Das klassische {brand} Erlebnis, wofür man uns kennt.',
        charity: 'Charity {brand}',
        charity_text:
          'Bei einer Charity {brand} wirst du definitiv für den guten Zweck malen! 5% aller Einnahmen gehen an eine Wohltätigkeitsorganisation.',
        small_group: 'Kleine Gruppe {brand}',
        small_group_text:
          'Unsere kleine Gruppen {brand} ermöglichen mehr individuelle Betreuung mit maximal 15 Teilnehmern pro Event.',
        collaboration: 'Kollaboration {brand}',
        collaboration_text:
          'Bei unseren Kollaboration arbeiten wir mit exklusiven Unternehmen zusammen, um dir ein einzigartiges {brand} Erlebnis zu bieten.'
      },
      skill: {
        beginner: 'Anfänger',
        intermediate: 'Fortgeschritten',
        advanced: 'Profi'
      },
      filter: {
        city: 'Stadt',
        date: 'Datum',
        date_placeholder: 'Wählen Sie die passenden Tage für Sie.',
        skill: 'Level',
        type: 'Event Typ',
        host: 'Künstler:in',
        theme: 'Motiv',
        theme_category: 'Kategorien'
      },
      dayNames: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
      monthNames: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember'
      ]
    },
    kids_events: {
      title:
        'ArtNight ist der perfekte Malkurs für Kinder ab 5 Jahren, die ihre kreative Seite entdecken möchten. Unsere erfahrenen ArtNight Künstler führen die Kinder in die Welt der Farben und Formen ein und unterstützen sie dabei, ihre eigenen künstlerischen Fähigkeiten zu entwickeln.',
      subtitle:
        'Durch das Malen und Gestalten lernen die Kinder, ihre Gedanken und Gefühle auszudrücken und sich besser auf eine Aufgabe zu konzentrieren.',
      meta: {
        title: 'Gemeinsame Malkurse für Kinder und Eltern | ArtNight',
        description:
          'Male mit deinem Kind zwei tolle Bilder und nehmt eure Kunstwerke anschließend als schöne Erinnerung mit nach Hause!'
      }
    }
  },
  voucher: {
    voucher: 'Gutschein',
    multiticket: 'Multiticket',
    hero_title: 'Ein {brand} Gutschein ist das perfekte Geschenk!',
    product_options: {
      header: 'Grußbotschaft',
      voucher: 'Grußbotschaft'
    },
    delivery_text: 'Direkt nach dem Kauf senden wir dir den Gutschein per E-Mail zu.',
    multitickets: 'Multitickets',
    choose_amount: 'Wähle den Betrag',
    choose_motive: 'Wähle ein Gutschein Motiv aus',
    choose_message: 'Personalisiere deinen Gutschein mit einer Grußbotschaft',
    write_message: 'Eigenen Text schreiben',
    write_message_placeholder: 'Grußbotschaft schreiben...',
    characters_left: 'Zeichen übrig',
    usability_warning:
      'Geschenkgutscheine können für alle Produkte eingelöst werden, außer für weitere Geschenkgutscheine.',
    artnight: {
      listing_hero_headline: 'Für alle die Kreativität schenken wollen.',
      grid_title: 'Entdecke weitere <br>{brand}-Gutscheine',
      listing_hero_content:
        'Mit unseren ArtNight-Gutscheinen entdeckt jede:r seine kreative Ader – das perfekte Geschenk!',
      listing_hero_list_items: [
        'Ein unvergessliches Erlebnis in Gemeinschaft - denn zusammen kreative Zeit zu verbringen ist mit das Wertvollste.',
        'Individuell und einzigartig - am Ende hat jeder seine eigene Kreation zum Verschenken oder zum Dekorieren der eigenen vier Wände.',
        'Das Rund-Um-Sorglos Paket - unsere Künstler bringen alles und leiten euch Schritt für Schritt ins Ziel.'
      ],
      voucher_personalisation_options: [
        {
          name: 'Geburtstag',
          text: "Alles Liebe zum Geburtstag! Gönn' dir eine kreative Pause bei einer ArtNight."
        },
        {
          name: 'Muttertag',
          text: 'Liebe Mama, ich wünsche dir alles Liebe zum Muttertag! Du hast dir eine kleine kreative Pause verdient.'
        },
        {
          name: 'Weihnachten',
          text: 'Frohe Weihnachten! Viel Spaß bei einer kreativen Pause bei einer ArtNight.'
        },
        {
          name: 'Dankeschön',
          text: 'Ein kleines Dankeschön für dich! Du hast dir eine kreative Pause verdient.'
        },
        {
          name: 'Für Kollegen',
          text: 'Danke für die tolle Zusammenarbeit - wir finden, du hast dir eine kreative Pause bei einer ArtNight verdient.'
        },
        {
          name: 'Valentinstag 💖',
          text: 'Schönen Valentinstag! Du bist etwas ganz Besonderes und hast dir eine kreative Pause bei einer ArtNight verdient.'
        },
        {
          name: 'Unausgefüllt',
          text: ' '
        }
      ],
      hero_text: 'Zeit fürs perfekte Geschenk!',
      giftcard_text:
        'Wähle einen passenden Betrag für deinen Gutschein aus und personalisiere ihn nach Belieben mit einem Motiv und einer persönlichen Grußbotschaft.',
      multiticket_text:
        'Multitickets enthalten mehrere Gutscheincodes mit einem Wert von jeweils {unitPrice} €. Je größer das Multiticket, desto mehr sparst du!'
    }
  },
  event: {
    find_your_artnight: 'Deine ArtNight finden',
    copyright_image: 'Quelle des Beispielbildes: {content}',
    copyright_theme: 'Quelle des Motivs: {content}',
    theme_included_title: 'Was alles dazu gehört',
    theme_learnings_title: 'Was du lernen wirst',
    livestream_from: 'Live from {city}',
    online_text: 'Online ArtNight',
    outdoor_text: '{brand} im Freien',
    collaboration_text: 'Kollaboration',
    collaboration_with_text: 'Kollaboration mit {name}',
    select_date_text: 'Bitte wähle ein Datum',
    more_events_text: 'Weitere Termine',
    upsell_header_text: 'Passend dazu',
    sales_stopped_text: 'Verkaufsschluss',
    time_text: '{start} - {end} Uhr',
    reviews_title: 'Bewertungen',
    sold_out_text: 'Ausverkauft',
    ticket_qty_text: '{qty} Ticket | {qty} Tickets',
    last_chance_text: 'Nur noch {qty} Ticket! | Nur noch {qty} Tickets!',
    artnight_meta_title: 'Am {date} um {time} malt {host} mit euch das Motiv {theme}',
    about_materials_title: 'Die Materialien',
    about_bundle_title: 'Über dieses Bundle',
    price_message: 'Teil',
    two_days_event: '2-teiliges Event',
    select_date: 'Datum wählen',
    select_date_empty_state_with_city_text:
      'Sorry, wir bieten derzeit keine weiteren Events mit diesem Motiv in {city} an.',
    select_date_empty_state_text: 'Sorry, wir bieten derzeit keine weiteren Events mit diesem Motiv an.',
    select_city: 'Stadt wählen',
    price_per_ticket: 'Preis pro Ticket',
    features: {
      title: 'Das Wichtigste vorab',
      basic_level: 'Anfänger Level',
      pro_level: 'Fortgeschrittenes Level',
      advanced_level: 'Profi',
      materials: {
        shipment: 'Material nach Hause',
        purchase: 'Bestell dir unser Materialset',
        more_info: 'Mehr Materialinformationen findest du hier'
      },
      materials_included: 'Alle Materialien inklusive',
      materials_not_included:
        '<a href="https://help.artnight.com/hc/de/articles/12455852875036-Was-ist-eine-Online-ArtNight" target="blank" class="cl-primary ff-bold">Mehr Materialinformationen findest du hier</a>',
      painting_info: 'Motiv kann mit Acryl, Wasser oder Lebensmittelfarbe gemalt werden',
      material_set:
        'Wir schicken dir gerne unser ArtNight Material nach Hause: <a href="/products/artnight-acryl-material-kit/" class="cl-primary ff-bold">Bestell dir unser Materialset</a>',
      with_recipe: 'Inklusive Rezept & Einkaufsliste',
      host: 'Mit {host}',
      fun: 'Sorgloser Spaß.',
      corona_notice: 'Unser Konzept für die Sicherheit unserer Teilnehmer',
      video_course: 'Male in deinem Tempo, wann und wo du willst',
      livestream_text:
        'Für die perfekte Vorbereitung erhältst du von uns zwei Videokurse gratis obendrauf: Pinseltechnik \uD83D\uDD8C und Farbenlehre \uD83C\uDFA8.',
      duration_text: '<strong>Dauer</strong>: ca. {min} Min',
      preparation_text: '<strong>Vorbereitungszeit</strong>: {min} Min',
      language_text: 'Sprache: {languages}',
      languages: {
        German: 'Deutsch',
        English: 'Englisch'
      },
      short_languages: {
        de: 'Deutsch',
        Deutsch: 'Deutsch',
        en: 'Englisch',
        Englisch: 'Englisch',
        fr: 'Französisch',
        Französisch: 'Französisch'
      },
      levels: {
        1: 'Alle Level, auch Neueinsteiger:innen',
        2: 'Fortgeschrittenes Level, ideal für alle die schon mal einen Pinsel in der Hand hatten',
        3: 'Profi Level, ideal für alle mit ArtNight oder anderweitige Malerfahrung'
      },
      description_title: 'Was <em>dich</em> erwartet',
      buy_group_booking: 'Privates Gruppenevent anfragen',
      buy_gift_card: 'Geschenk-Idee? ArtNight verschenken'
    },
    bundles: {
      title: 'What’s included?',
      title_parent: 'Auch Teil von:',
      more_link: 'Mehr erfahren'
    },
    subtitles: {
      video_course: 'Enthält {qty} Videokurs | Enthält {qty} Videokurse',
      artnight: 'Malen mit Acryl',
      handlettering: 'Lerne Handlettering und Brushlettering',
      'handlettering-pro': 'Lerne fortgeschrittene Techniken zu Handlettering und Brushlettering'
    },
    segmentation_label: {
      kompakt: 'Kompakt',
      compact: 'Kompakt',
      classic: 'Classic',
      premium: 'Premium'
    }
  },
  theme: {
    seo_title: 'Male das Motiv {theme} - ArtNight',
    seo_description:
      'Male das Motiv {theme} in einem unserer spannenden Malkurse in vielen Städten Deutschlands und Österreichs!'
  },
  events: {
    seo_title: '{title} - ArtNight am {date}, {venue}',
    seo_online_title: '{title} - Online ArtNight am {date}',
    seo_description: 'Am {date} um {time} malt {host} mit euch das Motiv {title} - {venue} in {city}',
    seo_online_description: 'Am {date} um {time} malt {host} online mit euch das Motiv {title}',
    more_info: 'Mehr Info',
    back_to_events: 'Zu den Events',
    regular_upcoming_in: '{brand}s in ',
    upcoming_in: '{brand}s in',
    upcoming_in2: 'Zukünftige {brand}s in',
    upcoming_in3: 'Zukünftige {brand}s in',
    more_upcoming_in: '{brand}s in',
    upcoming: 'Zukünftige {brand}s',
    upcoming_from: 'Weitere {brand}s von',
    upcoming_online: 'Weitere Online {brand}s',
    load_more: 'Mehr Events anzeigen',
    no_more_events: 'Weitere Events sind in Planung.',
    upcoming_host: 'Weitere Motive mit Host {host}',
    upcoming_city: 'Weitere Artnights in {city}',
    no_results: 'Keine Ergebnisse gefunden!',
    load_more: 'Mehr anzeigen',
    price_unit: '/ Ticket',
    events_in: 'Malkurse in',
    nearby_you: 'deiner Nähe'
  },
  venue: {
    label: 'Die Location',
    undefined_label: 'Location Ausstehend',
    undefined_description:
      'Die genaue Location teilen wir dir spätestens 48 Stunden vor Eventbeginn per Email und hier auf der Webseite mit. Dein Event wird auf jeden Fall in {city} stattfinden!',
    upcoming_events: 'Weitere {brand}s in dieser Location',
    subtitle: 'Deine Location',
    open_map: 'Auf Google Maps anzeigen',
    has_parking: 'Parking lots available',
    has_accessibility: 'Accessible for disabled people'
  },
  host: {
    label: 'Host',
    course_management: 'Kursleitung',
    events_held_label: 'Gegebene Events',
    artnight_label: 'Mit deinem Künstler:in',
    search_host: 'Suche eine:n Künstler:in'
  },
  rebooking: {
    resend_booking_title: 'Buchungsbestätigung anfordern',
    title: 'Ticketumbuchung',
    order_id_label: 'Bitte trage deine Buchungsnummer / Order-ID ein. Diese findest du in deiner Buchungsbestätigung.',
    resend_email_label:
      'Du findest deine Buchungsbestätigung nicht? Dann gib bitte deine E-Mail Adresse ein, die du bei deiner Buchung verwendet hast und wir schicken dir in den nächsten Minuten deine Buchungsbestätigung zu.',
    ticket_select_label: 'Wähle die Tickets aus, die du gerne umbuchen möchtest.',
    confirm_text_1:
      'Yipeee. Du hast soeben von uns eine Email erhalten, um Deine Stornierung noch einmal zu bestätigen. Bitte überprüfe auch deinen Spam-Ordner.',
    confirm_text_2: 'Nach Bestätigung bekommst Du direkt eine Email mit Deinem neuen Gutscheincode zugeschickt.',
    reason_label: 'Nenne uns den Grund der Umbuchung:',
    reason_placeholder: 'Grund der Umbuchung',
    thankyou_text: 'Danke. Du hast es fast geschafft!',
    info_line_1: 'Bitte beachte',
    info_line_2:
      'Die {company} ist rechtlich nicht dazu verpflichtet, eine Umbuchung der Tickets zu ermöglichen*. Eine Umbuchung ist aus Kulanz nur bis zu 48 Stunden vor Stattfinden des Events möglich. Wenn du dich für eine Umbuchung entscheidest, bekommst du einen Gutscheincode zugeschickt, mit dem du eine neue {brand} buchen kannst. Details zu unseren Stornierungebedingungen findest du {rebooking_link}.',
    info_line_3: '*AGB der {company}',
    info_line_4: 'Weitere Fragen? Kontaktiere gerne unser Team über dieses {support_link}.',
    contact_form: 'Kontaktformular',
    button_next: 'Weiter',
    button_back: 'Zurück',
    reason_options: {
      corona: 'Coronavirus',
      illness: 'Krankheit',
      prevented: 'Zeitlich verhindert',
      another_interest: 'Interesse an einer anderen {brand}',
      order_mistake: 'Fehlbuchung',
      change: 'Änderung der Uhrzeit/Datum/Location der {brand}',
      other: 'Sonstiges'
    },
    email_success_thank_you: 'Vielen Dank für deine Anfrage!',
    email_success_text:
      'Sofern wir eine Buchung mit deiner E-Mailadresse finden können, senden wir dir deine Bestellbestätigung in wenigen Minuten erneut zu. Solltest du keine E-Mail erhalten, prüfe bitte die Richtigkeit deiner E-Mailadresse und deinen Spam-Ordner. Bei fragen kannst du dich jederzeit an unseren Kundenservice wenden:',
    text_redirect_resend_email: 'Wenn du deine Buchungsnummer nicht zur Hand hast,',
    linked_redirect_resend_email: 'kannst du hier eine neue Buchungsbestätigung anfordern.',
    help_and_contact: 'Hilfe & Kontakt',
    step_5_subtitle: 'Bitte klicke den Link in deiner Stornierungsbestätigung um den Vorgang abzuschließen.',
    step_5_step_1: 'Öffne die E-Mail für deine Stornierungsbestätigung',
    step_5_step_2: 'Klicke auf den Link',
    step_5_step_3: 'Und erhalte eine neue E-Mail mit deinem Gutschein'
  },
  cancellations: {
    thankyou_text: 'Vielen Dank für Deine Rückmeldung!'
  },
  cart: {
    open: 'Warenkorb öffnen',
    button_text: 'In den Warenkorb',
    price_per_item_text: 'Stückpreis',
    added_voucher_text: 'Angewendeter Gutscheincode',
    total_text: 'Gesamtsumme',
    subtotal_text: 'Zwischensumme',
    qty_text: 'Anzahl',
    voucher_code_label: 'GUTSCHEINGESCHENK',
    empty_text: 'Dein Warenkorb ist derzeit leer.',
    to_checkout_text: 'Zur Kasse gehen',
    continue_shopping_text: 'Weiter shoppen',
    pay_button: 'Bezahlen',
    add_to_cart_text: 'Jetzt buchen | Jetzt kaufen',
    empty_cart_text: 'Dein Einkaufswagen ist derzeit leer. Bitte füge noch Produkte hinzu, bevor du zur Kasse gehst'
  },
  coupons: {
    add_coupon_text: 'Du hast einen Gutscheincode? Klicke hier!',
    add_coupon_info_text:
      "Rabattaktionen sind nicht mit anderen Coupons kombinierbar. <br>Details zum Zahlen mit Gutscheinen findest du <a href='https://help.artnight.com/de/support/solutions/folders/42000106111' target='_blank'><u>hier</u></a>.",
    input_placeholder: 'Code eingeben',
    success_text: 'Gutschein-Code erfolgreich angewendet.',
    error_text: 'Der angegebene Gutscheincode ist nicht gültig.'
  },
  checkout: {
    checkout_button: 'Zur Kasse gehen',
    title: 'Dein Warenkorb',
    order_as_company: 'Als Unternehmen bestellen',
    payment_title: 'Zahlungsart',
    billing_title: 'Persönliche Angaben',
    order_title: 'Deine Bestellung',
    order_button_submit: 'Bestellung abschicken',
    free_order_button_submit: 'Bestellung kostenlos abschicken',
    select_payment_gateway: 'Wähle deine bevorzugte Zahlungsmethode',
    shop_more: 'Ich möchte noch weitershoppen',
    newsletter_check:
      'Für den Newsletter anmelden und über Aktionen, Rabatte, Gewinnspiele und neue Termine informiert werden.',
    terms_check: 'Ich habe die {link} gelesen und stimme ihnen zu. *',
    terms_link: 'Geschäftsbedingungen',
    credit_card: 'Kreditkarte',
    coupon_text: 'Gutschein eingeben',
    email_text: 'Hier werden wir dir die Bestätigung deiner Bestellung hinsenden.',
    select_payment_text: 'Zahlungsart auswählen',
    validation_failed: 'Bitte füllen Sie die Felder aus.',
    voucher_upsell: {
      text: 'Wenn du möchtest, kannst du hier deiner Bestellung einen {brand} Gutschein hinzufügen.'
    },
    giftcard_warning:
      'Ein Geschenkgutschein kann für alle Produkte verwendet werden, außer für weitere Geschenkgutscheine.',
    order: {
      number: 'Bestellnummer',
      email_text: 'Deine Email',
      email_info_text:
        'Falls du innerhalb von 10 Minuten nach der Buchung keine Bestätigungsmail erhältst, dann überprüfe bitte alle betreffenden E-Mail-Ordner: Spam, Junkmail, Werbung usw.',
      invoice_details: 'Rechnungsempfänger',
      title: 'Bestellung',
      thankyou_text: 'Vielen Dank! Deine Bestellung ist bei uns eingegangen.'
    },
    thank_you: {
      applied_coupon: 'Angewendeter Coupon',
      applied_voucher: 'Angewendeter Gutscheincode'
    },
    empty_cart_warning: 'Sie können keine Bestellung erstellen, wenn sich keine Artikel im Warenkorb befinden.'
  },
  form: {
    first_name: 'Vorname',
    last_name: 'Nachname',
    name: 'Name',
    email: 'E-Mail-Adresse',
    address: 'Straße und Hausnummer',
    address_additional: 'Zusätzliche Addresse',
    street: 'Straße',
    number: 'Hausnummer',
    city: 'Ort',
    postcode: 'Postleitzahl',
    country: 'Wähle ein Land',
    phone: 'Mobilfunknummer',
    company: 'Name der Firma',
    tax_id: 'Steueridentifikationsnummer',
    notes: 'Anmerkungen',
    additional_information: 'Zusätzliche Informationen',
    phone_notice: 'Über diese Nummer wirst du im Falle von Änderungen der {brand} informiert.',
    confirm: 'Bestätigen',
    participants: 'Teilnehmer*innen',
    language: 'Sprache',
    start_time: 'Startzeit',
    separate_invoice: 'Ich benötige eine separate Rechnung',
    venue: {
      options: [
        {
          label: 'Ja, die Location steht bereits fest',
          value: 'have_venue'
        },
        {
          label: 'Nein, bisher noch nicht',
          value: 'no_venue'
        }
      ]
    },
    validations: {
      required_field: 'Feld ist erforderlich',
      name_minlength: 'Der Name muss mindestens 2 Buchstaben haben.',
      valid_email: 'Bitte geben Sie eine gültige E-Mail-Adresse an.',
      valid_city: 'Der von Ihnen eingegebene Stadtname scheint nicht gültig zu sein',
      valid_postcode: 'Die Postleitzahl muss mindestens 3 Buchstaben haben.',
      valid_phonenumber: 'Bitte geben Sie eine gültige Telefonnummer ein.',
      valid_tax_id: 'Die Steuernummer muss aus mindestens 3 Buchstaben bestehen.',
      numeric: 'Dieses Element sollte eine Zahl sein.'
    }
  },
  payment: {
    processing: 'Auftragsbearbeitung...',
    failed_title: 'Fehlgeschlage Bestellung',
    failed_text: 'Die Bezahlung für Bestellung #%s von %s ist fehlgeschlagen. Bitte versuche es erneut.',
    failed: {
      title: 'Zahlung fehlgeschlagen',
      text: 'Wir können deine Zahlung leider nicht abschließen. Dein Warenkorb ist für 30 Minuten gespeichert.',
      back_to_cart: 'Zurück zum Warenkorb',
      other_payment_method: 'um eine andere Zahlungsmethode zu wählen',
      contact_support_button: 'Kontakt zum Support'
    },
    pending: {
      title: 'Zahlungsabwicklung',
      text: 'Bitte schließe oder aktualisiere die Seite nicht. Wir wickeln deine Zahlung gerade ab.'
    },
    error: {
      updating_cart: 'Fehler beim Aktualisieren des Warenkorbs',
      payment_failed: 'Fehler bei der Zahlungsabwicklung'
    }
  },
  footer: {
    constent_preferences: 'Cookie-Einstellungen',
    customer_service: 'Kundenservice',
    offer: 'Angebote',
    social_media: 'Social Media',
    countries: {
      de: 'Deutschland',
      ch: 'Schweiz',
      nl: 'Niederlande',
      uk: 'UK',
      at: 'Österreich'
    },
    instagram_text:
      'Folge uns auf Instagram, verlinke deine Bilder mit dem Hashtag {hashtag} und habe monatlich die Chance eine {brand} für 2 Personen zu gewinnen und von uns gerepostet zu werden.',
    cookie_button_text: 'Zustimmen',
    cookie_text:
      'Diese Website verwendet Cookies – nähere Informationen dazu und zu Ihren Rechten als Benutzer finden Sie in unserer Datenschutzerklärung. Klicken Sie auf „Zustimmen”, um Cookies zu akzeptieren.'
  },
  newsletter: {
    footer_title: 'Newsletter',
    placeholder_email: 'E-Mail',
    placeholder_city: 'Wähle deine Stadt',
    button_submit_text: 'Abschicken',
    button_send_text: 'Jetzt anmelden',
    button_sent_text: 'Sent',
    widget_title: 'Folge uns in die Welt der Kreativ-Flows',
    event_sold_out_title: 'Warteliste',
    event_sold_out_text:
      'Aktuell ist diese ArtNight ausgebucht. Wir informieren dich, sobald es neue Daten oder freie Spots dafür gibt.',
    artnight: {
      subscribed_text:
        'Fast geschafft! Du hast soeben eine Email erhalten - einfach Anmeldung bestätigen und ArtNight News erhalten!',
      homepage_text:
        'Neue Events und Angebote? Wann kommt ArtNight endlich in deine Stadt? Mit dem ArtNight Newsletter weißt du sofort Bescheid. Melde dich jetzt an und werde regelmäßig über neue Termine und tolle Aktionen informiert!',
      thank_you_text:
        'Du hast Dich erfolgreich für unseren Newsletter angemeldet. Willkommen in unserem ArtNight-Universum. Ab jetzt erwarten Dich exklusive Rabattcodes, Gewinnspiele und der heißeste Shit rund um ArtNight. Darauf stoßen wir erstmal an!?'
    },
    footer_text: 'Melde dich jetzt an und werde regelmäßig über neue Termine und tolle Aktionen informiert!',
    validations: {
      email: 'Die eingegebene Email Adresse ist nicht korrekt.',
      required: 'E-Mail ist ein Pflichtfeld.',
      city_required: 'Stadt ist ein Pflichtfeld.'
    },
    landing: {
      title: 'Hurra, Du bist dabei!',
      text_1: 'Du hast Dich erfolgreich für unseren Newsletter angemeldet. Willkommen in unserem {brand}-Universum.',
      text_2:
        'Ab jetzt erwarten Dich exklusive Rabattcodes, Gewinnspiele und der heißeste Shit rund um {brand}. Darauf stoßen wir erstmal an! ?'
    }
  },
  press: {
    reviews: 'Das sagt unsere Community',
    contact: 'Pressekontakt',
    title: 'Presseportal',
    title_section1: 'Pressemitteilungen',
    title_section2: 'Pressespiegel',
    title_section3: 'Media Downloads'
  },
  validations: {
    email: 'Die eingegebene Email Adresse ist nicht korrekt.',
    required: 'Feld ist erforderlich',
    min_length: 'Das Feld muss mindestens {min} Zeichen enthalten.',
    city: 'Der von Ihnen eingegebene Stadtname scheint nicht gültig zu sein',
    postcode: 'Die Postleitzahl muss mindestens {min} Buchstaben haben.',
    tax_id: 'Die Steuernummer muss aus mindestens {min} Buchstaben bestehen.'
  },
  event_attendee_registration_success: {
    registration: 'Deine Registrierung war erfolgreich!',
    confirmation_one: 'Bitte halte diese Bestätigung vor deiner',
    confirmation_two: 'bereit, um sie deinem/deiner Workshopleiter:in zu zeigen.',
    fun: 'Wir wünschen dir ganz viel Spaß!'
  },
  event_attendee_registration: {
    headline: 'Bitte trage dich in unsere Teilnehmerliste ein!',
    no_events_found: 'Sorry, heute findet leider kein Event statt.'
  },
  listings: {
    sort_by: 'Sortieren nach:',
    video_courses: {
      hero_title: 'ArtNight Videokurse',
      hero_description:
        'Mit unseren neuen Videokursen stehen dir eine Vielzahl von Motiven zur Auswahl, die du ganz entspannt in deinem eigenem Tempo malen kannst.',
      bundles_title: 'Bundles',
      bundles_description: 'Unsere Videobundles: Thematisch passende Videokurse zusammen kaufen und dabei Geld sparen!',
      courses_title: 'Einzelne Videokurse',
      bundles_courses_count: 'ENTHÄLT KEINE VIDEOKURSE | ENTHÄLT EINE VIDEOKURSE | ENTHÄLT {count} VIDEOKURSE'
    },
    sortings: {
      'slug:asc': 'Alphabetisch, A-Z',
      'slug:desc': 'Alphabetisch, Z-A',
      'price:asc': 'Preis, niedrig nach hoch',
      'price:desc': 'Preis, hoch nach niedrig',
      'created_at:asc': 'Datum, alt zu neu',
      'created_at:desc': 'Datum, neu zu alt'
    }
  },
  group_events: {
    back_to_page: 'Zurück zur Übersicht',
    details: {
      location_sub: 'Bitte gib hier die Adresse des gewünschten Veranstaltungsortes an'
    },
    options: {
      eventDateTime: '{date} um {time}',
      eventDate: 'Datum',
      eventTime: 'Time',
      eventLocation: 'Ort',
      locationCity: 'City',
      locationAddress: 'Address',
      locationPostalCode: 'Postal Code',
      locationContact: 'Kontakt',
      numberOfParticipants: 'Anzahl Teilnehmer*innen'
    },
    thank_you: {
      heading: 'Yay! Danke für deine Buchung.',
      subheading: 'Wir melden uns bei dir mit weiteren Infos zu eurem Event.',
      back_to_home: 'Zurück Zur Homepage',
      links: 'Hast du noch fragen? {email} oder {phone}'
    },
    header: {
      title: 'Lass uns deine ArtNight planen – in nur vier Schritten!',
      subtitle:
        'Wir stellen dir einige Fragen, um die perfekte ArtNight für euer Event zu finden. Du kannst danach noch immer alles ändern.'
    },
    participants: {
      title: '1 – Teilnehmende',
      subtitle: 'Bis zu 15 Personen bleibt der Mindestpreis von 660 € bestehen',
      count: 'Wie viele Personen werden teilnehmen?',
      less_than_6: 'Ihr seid weniger als 6 Personen? Auch bei öffentlichen ArtNights könnt ihr als Gruppe teilnehmen.',
      public_artnight_link: 'Zu den öffentlichen ArtNights',
      more_than_25:
        'Bei Buchungen über 25 Personen kümmern wir uns gerne individuell um deine Anfrage. Du kannst uns diese im nächsten Schritt unverbindlich zukommen lassen.'
    },
    location: {
      title: '2 – Location',
      subtitle_1: 'Wie möchtet ihr euer Event veranstalten?',
      subtitle_2: 'Wo soll euer Event stattfinden?',
      subtitle_3: 'Und in was für einer Location?',
      options: {
        customer: 'Eigene Räumlichkeiten'
      },
      cta: {
        title: 'Event vor Ort',
        feature_line_1: 'Alle Materialien werden von uns mitgebracht',
        feature_line_2: 'Event in eurer Location',
        button_selected: 'Ausgewählt',
        price: 'Ab {price}'
      }
    },
    themes: {
      title: '3 – Motiv-Wahl',
      subtitle: 'Keine Sorge: Das Motiv kann auch später noch geändert werden, solltet ihr euch umentscheiden.',
      details_modal_title: 'Infos zum Motiv',
      modal_title: 'Alle ArtNight Motive ({count})',
      load_more_text: 'Alle {count} Motive durchsuchen',
      description_title: 'Was euch erwartet',
      details_text: 'Details anzeigen'
    },
    date_time: {
      title: '4 – Datum & Uhrzeit',
      subtitle: 'Events, die weniger als 14 Tage im Voraus angefragt werden, prüfen wir gerne auf ihre Machbarkeit.',
      time: 'Um welche Uhrzeit soll euer Event stattfinden?',
      flexible_time: 'Wir sind zeitlich flexibel'
    },
    summary: {
      subtitle: 'Übersicht deines Events',
      button_text: 'Weiter zur Zusammenfassung',
      button_short_text: 'Zur Zusammenfassung',
      total_text_with_tax: 'Gesamtbetrag (inkl. MWST)',
      participants_text: '{price} x {number} Teilnehmende',
      total_text: 'Gesamtbetrag'
    },
    footer: {
      button: 'Dein Event buchen'
    },
    head_title: 'ArtNight: Erstellen Sie Ihre private Gruppenveranstaltung'
  },
  founder: {
    title: 'Aimie, Gründerin von ArtNight',
    text: '„Artnight ist eine Plattform und eine kreative Community, die glaubt, dass Kreativität der Schlüssel zu einem erfüllten Leben ist.“',
    ctaText: 'Mehr über uns',
    video_title: 'Erlebe spannende Malkurse mit ArtNight!'
  },
  offline_message: 'Du bist offline. Einige Funktionen sind möglicherweise nicht verfügbar.',
  product_page: {
    title: {
      events: 'Malkurse in deiner Nähe | {themeCategoryNames} malen',
      motiv: 'Alle Kategorien | {themeCategoryNames} malen',
      city: 'Malkurse in {city} | {themeCategoryNames} malen in {city} ',
      kids: 'Malen mit Kindern'
    },
    sub_title: 'Wähle ein Motiv für deine kreative Auszeit'
  }
};
