import { ThemeCategory } from '@odyssey/realtainment-api';
import { Context } from '@odyssey/core';
import { hack__replaceJpegWithJpg } from '../helpers';
const getName = (theme: ThemeCategory): string => theme.name;

const getImage = (context: Context, themeCategory: ThemeCategory): string => {
  const { $pimcore } = context.$vsf;
  if (!themeCategory.categoryDesktopImage) return '';
  return `${$pimcore.config.assetsUrl}/_default_upload_bucket/image-thumb__${themeCategory.categoryDesktopImage?.id}__thumbnail36x36/${hack__replaceJpegWithJpg(themeCategory.categoryDesktopImage?.filename)}`;
};

export const themeCategoryGetters = {
  getName,
  getImage
};
