//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { onMounted, onBeforeMount, watch, computed, useRouter, useRoute, useContext } from '@nuxtjs/composition-api';
import { onSSR } from '@odyssey/core';
import { useStoryblokGlobalComponents, useCart, useCategory, formatGtm } from '@odyssey/realtainment';
import { useGlobalUI } from '@/composables/useGlobalUI';
import useUiState from '@/composables/useUiState';
// import axios from 'axios';

// const COOKIES_STORAGE_KEY = 'cookiesAccepted';

export default {
  name: 'Default',
  components: {
    SNotificationBar: () =>
      import(
        /* webpackChunkName: "chunk-ui-molecules-notifications" */ '@/components/UI/molecules/notifications/SNotificationBar'
      ),
    Notification: () =>
      import(
        /* webpackChunkName: "chunk-ui-molecules-notifications" */ '@/components/UI/molecules/notifications/Notification'
      ),
    CityList: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/CityList'),
    SHeader: () => import(/* webpackChunkName: "chunk-header" */ '@/components/Header/SHeader'),
    SFooter: () => import(/* webpackChunkName: "chunk-ui-organisms" */ '@/components/UI/organisms/SFooter'),
    CitySelect: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-selects" */ '@/components/UI/molecules/selects/CitySelect'),
    CookieNotification: () =>
      import(
        /* webpackChunkName: "chunk-ui-molecules-notifications" */ '@/components/UI/molecules/notifications/CookieNotification'
      ),
    OfflineBadge: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/OfflineBadge'),
    ProductListingFiltersModal: () =>
      import(/* webpackChunkName: "chunk-page-category" */ '@/components/ProductListing/ProductListingFiltersModal')
  },
  setup(_, { root }) {
    const router = useRouter();
    const route = useRoute();
    const context = useContext();
    const { i18n, $gtm, $config } = context;
    const { load: loadCart } = useCart();
    const { search: categorySearch } = useCategory();
    const { fetch: fetchGlobalComponents } = useStoryblokGlobalComponents();
    const { setGlobalUI, isGlobalUIActive } = useGlobalUI();
    const { isCartSidebarOpen } = useUiState();

    const showCityList = computed(() => {
      return (
        route.value.name.includes('variable-route__') ||
        route.value.name.includes('themes-route') ||
        route.value.name.includes('index__')
      );
    });

    watch(
      () => isGlobalUIActive('SideNav').value || isCartSidebarOpen.value,
      (val) => {
        if (process.client && !root.$screen.md) {
          document.body.style.overflow = val ? 'hidden' : 'visible';
        }
      }
    );

    onSSR(async () => {
      await Promise.all([
        categorySearch({
          customQuery: {
            categoryList: 'categoriesQuery'
          }
        }),
        fetchGlobalComponents()
      ]);
    });

    function trackGtmPageLoad(route) {
      const options = {
        info: {
          name: document?.title?.toLowerCase(),
          url: $config.server.url + route.fullPath,
          path: route.path,
          referrer: document?.referrer || null,
          environment: $config.server.isProduction ? 'production' : 'staging'
        },
        category: {
          pageType: 'checkout'
        },
        attributes: {
          country: i18n?.defaultCountry?.toLowerCase(),
          language: i18n?.defaultLanguage?.toLowerCase()
        }
      };

      $gtm.push(formatGtm('all/PAGE_LOAD', options));
    }

    onBeforeMount(() => {
      router.beforeEach((to, from, next) => {
        setGlobalUI('CityModal', false);
        next();
      });
    });
    // const hostPort = ref(null);

    onMounted(async () => {
      loadCart();

      // await fetchData();
    });

    watch(
      route,
      async () => {
        if (process.browser) {
          trackGtmPageLoad(route.value);
        }
      },
      {
        immediate: true
      }
    );

    // currently throwing a lot of exceptions (2k/day) so paused till strategy is found

    // async function getRedisReferenceDate() {
    //   try {
    //     const { data } = await axios.get('/cache/reference-date/');

    //     return data ?? data;
    //   } catch (e) {
    //     console.error('error in reference date', e);
    //   }
    // }

    // async function getCachedData() {
    //   hostPort.value = window?.location.origin + '/';

    //   const cacheNames = [
    //     `workbox-precache-v2-${hostPort.value}`,
    //     `workbox-runtime-${hostPort.value}`,
    //     `workbox-googleAnalytics-${hostPort.value}`
    //   ];

    //   caches
    //     .keys()
    //     .then((keyList) =>
    //       Promise.all(
    //         keyList.map(async (key) => {
    //           console.log('key', key);
    //           if (cacheNames.includes(key)) {
    //             const cachedDatesData = await getCacheDates(key);
    //             if (cachedDatesData.length) {
    //               const filteredCaches = cachedDatesData.filter((cache) => cache !== undefined);
    //               await flushClientsCaches(filteredCaches);
    //             }
    //           }
    //         })
    //       )
    //     )
    //     .catch((e) => console.error('Error in getCachedData', e));
    // }

    // async function getCacheDates(key) {
    //   const cacheStorage = await caches.open(key);

    //   return cacheStorage
    //     .keys()
    //     .then((requests) => {
    //       return Promise.all(
    //         requests.map((request) => {
    //           return cacheStorage.match(request).then((response) => {
    //             if (response.status === 200 || response.ok) {
    //               const cachedDate = response.headers.get('date');
    //               const cachedDateIso = new Date(cachedDate).toISOString();
    //               return { cacheStorage, cachedDateIso, response };
    //             }
    //           });
    //         })
    //       );
    //     })
    //     .catch((e) => console.error('Error in get Cache dates', e));
    // }

    // async function flushClientsCaches(filteredCaches) {
    //   const redisDate = await getRedisReferenceDate();

    //   filteredCaches.map(({ cacheStorage, cachedDateIso, response }) => {
    //     if (redisDate.date < cachedDateIso) {
    //       const url = response.url.replace(hostPort.value, '/');
    //       cacheStorage.delete(url);
    //     }
    //   });
    // }

    // async function fetchData() {
    //   try {
    //     await getCachedData();
    //   } catch (e) {
    //     console.error('error in fetching data cache storage', e);
    //   }
    // }

    return {
      route,
      showCityList
    };
  },
  head() {
    if (!this.$config.server.isProduction) {
      return {
        meta: [{ vmid: 'robots', name: 'robots', content: 'noindex, nofollow' }]
      };
    }

    return {
      links: [
        {
          rel: 'preload',
          as: 'image',
          href: '/assets/artnight/logos/logo.svg'
        }
      ]
    };
  }
};
