//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getMetaData } from '@/helpers';
import { useHost, useVenue, useCategory, useTheme } from '@odyssey/realtainment';
import { computed, useContext } from '@nuxtjs/composition-api';
import { ShareNetwork } from 'vue-social-sharing/dist/vue-social-sharing';
import { themeGetters } from '@odyssey/realtainment';
export default {
  name: 'WebShare',
  props: {
    product: {
      type: Object
    },
    shareTheme: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ShareNetwork,
    NuxtTransformImage: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/NuxtTransformImage')
  },
  setup(props) {
    const context = useContext();
    const { categories } = useCategory();
    const { result: themes } = useTheme();
    const { result: hosts } = useHost();
    const { result: venues } = useVenue();
    const { $config, localePath } = context;
    const theme = computed(() => themeGetters.getThemeFromArray(themes.value, props.product));
    const metaData = computed(() => getMetaData(context, props.product, hosts.value, venues.value, categories.value));

    const url = computed(() => {
      if (process.client) {
        if (props.shareTheme) {
          return `${$config.server.url}${localePath({
            name: 'theme',
            params: {
              slug: themeGetters.getSlug(theme.value),
              themeId: theme.value.id
            }
          })}`;
        }

        if (props.product?.slug) {
          `${$config.server.url}${localePath({
            name: 'product-event',
            params: {
              slug: props.product.slug
            }
          })}`;
        }
      }

      return '';
    });

    return {
      networks: ['whatsapp', 'facebook', 'twitter', 'linkedin', 'email'],
      metaData,
      url,
      theme
    };
  }
};
