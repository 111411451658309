import Vue from 'vue'
import { createImage} from '~image'
import NuxtImg from '~image/components/nuxt-img.vue'
import NuxtPicture from '~image/components/nuxt-picture.vue'

import * as staticRuntime$34f1 from '/var/www/node_modules/@nuxt/image/dist/runtime/providers/static.js'
import * as ipxRuntime$6b74 from '/var/www/node_modules/@nuxt/image/dist/runtime/providers/ipx.js'
import * as pimcoreRuntime$6a3f from '/var/www/packages/realtainment/theme/plugins/nuxt-image-pimcore-provider.js'
import * as syliusRuntime$1637 from '/var/www/packages/realtainment/theme/plugins/nuxt-image-sylius-provider.js'
import * as storyblokRuntime$d01e from '/var/www/node_modules/@nuxt/image/dist/runtime/providers/storyblok.js'

const imageOptions = {
  "screens": {
    "xs": 1,
    "sm": 568,
    "md": 1024,
    "lg": 1238,
    "xl": 1536,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "general": {
      "modifiers": {
        "fit": "cover",
        "format": "webp",
        "quality": "90"
      }
    }
  },
  "provider": "ipx",
  "domains": [
    "pimcore-admin.ournights.rocks",
    "assets.artnight.com",
    "api.artnight.com"
  ],
  "alias": {}
}

imageOptions.providers = {
  ['static']: { provider: staticRuntime$34f1, defaults: {} },
  ['ipx']: { provider: ipxRuntime$6b74, defaults: {} },
  ['pimcore']: { provider: pimcoreRuntime$6a3f, defaults: {"fit":"cover","format":"webp","quality":"90"} },
  ['sylius']: { provider: syliusRuntime$1637, defaults: {"fit":"cover","format":"webp","quality":"90"} },
  ['storyblok']: { provider: storyblokRuntime$d01e, defaults: {"format":"webp","quality":"90"} }
}

Vue.component(NuxtImg.name, NuxtImg)
Vue.component(NuxtPicture.name, NuxtPicture)
Vue.component('NImg', NuxtImg)
Vue.component('NPicture', NuxtPicture)

export default function (nuxtContext, inject) {
  const $img = createImage(imageOptions, nuxtContext)

  if (process.static && process.server) {
    nuxtContext.beforeNuxtRender(({ nuxtState }) => {
      const ssrData = nuxtState.data[0] || {}
      ssrData._img = nuxtState._img || {}
    })
  }

  inject('img', $img)
}
