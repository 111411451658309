export default function (data) {
  return {
    module: 'SPicture',
    ...data,
    maxHeight: parseInt(data.maxHeight, 10) || 0,
    src: data.url && String(data.url).length ? data.url : data.src.filename,
    alt: data.src.alt && String(data.src.alt) ? data.src.alt : '',
    id: data.id ? data.id : ''
  };
}
