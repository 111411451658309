export default function ({ _editable, _uid, title, link, image, animation, id }) {
  return {
    module: 'SCarouselItemVoucherCategory',
    _uid,
    _editable,
    title,
    link,
    image,
    animation,
    id
  };
}
