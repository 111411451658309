//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { PRODUCT_ONLINE_EVENT_TYPE } from '@/helpers';
import { computed, useContext } from '@nuxtjs/composition-api';
import { formatGtm, venueGetters, hostGetters } from '@odyssey/realtainment';

export default {
  name: 'EventShortInfo',
  components: {
    NuxtTransformImage: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/NuxtTransformImage')
  },
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {}
    },
    host: {
      type: Object,
      default: () => {}
    },
    venue: {
      type: Object,
      default: () => {}
    },
    event: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const context = useContext();
    const { $gtm } = context;

    const isOnlineProduct = computed(() => props.product?.typeId === PRODUCT_ONLINE_EVENT_TYPE);
    const isOutdoorProduct = computed(() => props.event?.venueFacility === 'outdoor');

    function trackLocationMapClick(e) {
      $gtm.push(
        formatGtm('detail/EVENT_LOCATION_VIEW', {
          attributes: {
            link: e.target.href,
            name: props.venue?.name?.toLowerCase()
          }
        })
      );
    }
    return {
      isOnlineProduct,
      isOutdoorProduct,
      trackLocationMapClick,
      formatGtm,
      venueGetters,
      hostGetters,
      context
    };
  }
};
