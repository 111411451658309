import { useContext } from '@nuxtjs/composition-api';
import { venueGetters, hostGetters } from '@odyssey/realtainment';
import { fromUnixTime, addMinutes, parse as parseDate, isPast } from 'date-fns';
import { format as formatDate, utcToZonedTime } from 'date-fns-tz';
import { themeGetters } from '@odyssey/realtainment';
import de from 'date-fns/locale/de';

export const PRODUCT_EVENT_TYPE = 'event';
export const PRODUCT_EVENT_TYPES = 'events';
export const PRODUCT_ONLINE_EVENT_TYPE = 'online_event';
export const PRODUCT_ONLINE_EVENT_TYPES = 'online_events';
export const PRODUCT_MATERIAL_TYPE = 'material_kit';
export const PRODUCT_GIFTCARD_TYPE = 'giftcard';
export const PRODUCT_GIFTBOX_TYPE = 'gift_box';
export const PRODUCT_BUNDLE_TYPE = 'product_bundle';
export const PRODUCT_VIDEO_COURSE_TYPE = 'video_course';
export const AB_TESTING = 'ABTesting';
export const SOVENDUS_PROMOTION_TYPE = 'Affiliate_Sovendus';

export const CURRENCY_CODE = (i18n) => {
  return i18n.numberFormats[i18n.locale].currency.currency;
};

export function getPimcoreImage(id, image, type, upload_path = 'upload') {
  if (!image) return null;
  const { $config } = useContext();
  return [$config.api.assets_url, upload_path, `image-thumb__${id}__${type}`, image?.replace('.jpeg', '.jpg')].join(
    '/'
  );
}

export function getSyliusImage(image, width = 500, height = 500, crop = false, context = false) {
  if (!context) {
    context = useContext();
  }
  const $config = context.$config;
  const actionPath = crop ? $config.images.imageCropPath : $config.images.imageResizePath;
  return [
    $config.api.url,
    'vsbridge',
    $config.theme.channel,
    $config.images.imagePath,
    width,
    height,
    actionPath,
    image
  ].join('/');
}

export function getMetaData(context, product, hosts, venues, categories) {
  const { $config, i18n } = context;

  let title = $config.seo.titleFormat.replace('{title}', product?.name);
  let description = $config.seo.headDescription;

  if ([PRODUCT_ONLINE_EVENT_TYPE, PRODUCT_EVENT_TYPE].includes(product?.typeId)) {
    const venue = venues.find((v) => v.uuid === product.venueUuid);
    const host = hosts.find((h) => h.uuid === product.hostUuid);
    const city = categories.find((c) => c.slug === product.categorySlug);

    if (product.typeId === PRODUCT_EVENT_TYPE) {
      title = i18n.t('events.seo_title', {
        title: product?.name,
        date: product?.event_date?.date,
        venue: venue ? venueGetters.getName(venue) : ''
      });
      description = i18n.t('events.seo_description', {
        title: product?.name,
        venue: venue ? venueGetters.getName(venue) : '',
        host: host ? hostGetters.getName(host) : '',
        date: product?.event_date?.date,
        time: product?.event_date?.start_time,
        city: city?.name
      });
    } else if (product.typeId === PRODUCT_ONLINE_EVENT_TYPE) {
      title = i18n.t('events.seo_online_title', {
        title: product?.name,
        date: product?.event_date?.date
      });
      description = i18n.t('events.seo_online_description', {
        title: product?.name,
        host: host ? hostGetters.getName(host) : '',
        date: product?.event_date?.date,
        time: product?.event_date?.start_time
      });
    }
  }

  return {
    title,
    description
  };
}

export function getFormattedDate(date, dateFormat, context) {
  if (!context) {
    context = useContext();
  }
  const timeZone = context.$config.server.timeZone;
  return formatDate(utcToZonedTime(date, timeZone), dateFormat, { locale: de, timeZone });
}

export function getThumbnailImg(context, product, themes, themeGetters) {
  if (![PRODUCT_EVENT_TYPE, PRODUCT_ONLINE_EVENT_TYPE].includes(product.typeId))
    return getSyliusImage(product.thumbnail, 64, 64);

  const theme = themes.value.find((t) => t.uuid === product.eventThemeUuid);
  return theme ? themeGetters.getCoverImage(context, theme) : '';
}

export function isProductDisabled(product) {
  if (product) {
    return (
      product.quantity <= 0 ||
      isPast(parseDate(product.salesStopString, 'yyyy-MM-dd HH:mm', new Date())) ||
      !product.enabled
    );
  }
  return true;
}

export function prepareHost(host) {
  if (!host) {
    return {
      name: null,
      user: null,
      thumbnail: null
    };
  }
  return {
    name: host.publicName,
    user: host.user,
    thumbnail: getPimcoreImage(host.image?.id, host.image?.filename, 'thumbnail36x36')
  };
}
export function prepareProduct(product, context) {
  if (!product) return null;
  if (!context) {
    context = useContext();
  }
  let adjustedProduct = {
    ...product,
    stock: {
      qty: product?.quantity,
      is_in_stock: true
    }
  };

  const timeZone = context.$config.server.timeZone;

  if ([PRODUCT_EVENT_TYPE, PRODUCT_ONLINE_EVENT_TYPE].includes(adjustedProduct.typeId) && product.eventTimestamp) {
    const unixTime = fromUnixTime(product.eventTimestamp);
    const zonedTime = utcToZonedTime(unixTime, timeZone);
    adjustedProduct = {
      ...product,
      event_date: {
        string: formatDate(zonedTime, 'yyyy-MM-dd k:mm', { locale: de, timeZone }),
        day: formatDate(zonedTime, 'yyyy-MM-dd', { locale: de, timeZone }),
        date: formatDate(zonedTime, 'dd.MM.yyyy', { locale: de, timeZone }),
        start_time: formatDate(zonedTime, 'k:mm', { locale: de, timeZone }),
        end_time: formatDate(addMinutes(zonedTime, product.eventDuration), 'k:mm', { locale: de, timeZone })
      },
      stock: {
        qty: product.quantity,
        is_in_stock: product.quantity > 0
      }
    };
  }

  return adjustedProduct;
}
export function prepareProducts(products, context) {
  return products.map((product) => {
    return prepareProduct(product, context);
  });
}

export const getProductThumbnail = (context, product, themes, productThemeKey = 'eventThemeUuid') => {
  if (![PRODUCT_EVENT_TYPE, PRODUCT_ONLINE_EVENT_TYPE].includes(product.typeId)) {
    return getSyliusImage(product.thumbnail, 64, 64);
  }

  const theme = themes.find((t) => t.uuid === product[productThemeKey]);
  return theme ? themeGetters.getCoverImage(context, theme) : '';
};

// should come from Pimcore, for now it's hardcoded.
export const IMAGE_MAP = {
  '15eb7be1-08db-5296-80a2-1b0b1e749610': 'themeCategory--pop.svg',
  '0e7c3227-9b90-51fa-a23f-61b4e181b76b': 'themeCategory--typography.svg',
  '1c0029c1-731f-502a-9675-1dc2648d2c96': 'themeCategory--people.svg',
  '20cb6249-a5de-51bf-a0b8-03eb1f31d4cc': 'themeCategory--abstract.svg',
  '261af659-ae97-576b-8153-56b55c4537cc': 'themeCategory--symbol.svg',
  '3c5458f3-1bbf-574d-9b6a-8e6401d64850': 'themeCategory--land.svg',
  '9e994a76-dc67-5174-85c4-058ad920c866': 'themeCategory--face.svg',
  'ca6012ad-e9ea-51ee-8656-624ce7e2148b': 'themeCategory--bestseller.svg',
  'f6a82b86-420d-598a-b260-77f4943a7d1f': 'themeCategory--specials.svg',
  'ef48342d-7c96-5e48-aba6-339d843840ce': 'themeCategory--animal.svg',
  'dba4b785-4403-5375-98bb-5d4502a14496': 'themeCategory--still.svg',
  '24ea0c30-295f-5481-9698-6a3a25e1754b': 'themeCategory--botanik.svg',
  '6a6946fa-d29f-50e8-b746-25e976cdee90': 'themeCategory--new.svg',
  '45ba2dae-9b5f-58e7-b0fd-3632af077529': 'themeCategory--house.svg',
  '175596a3-79f3-5fbc-9a42-ecb31d696a0b': 'themeCategory--classic.svg',
  '8fbb5996-ff8b-546f-a835-ef344d98af55': 'themeCategory--actual.svg',
  'dc7822bd-39d6-5677-84db-ec8b3e434baa': 'themeCategory--actual.svg'
};

export const SORT_MAP = [
  'ca6012ad-e9ea-51ee-8656-624ce7e2148b',
  'f6a82b86-420d-598a-b260-77f4943a7d1f',
  '20cb6249-a5de-51bf-a0b8-03eb1f31d4cc',
  'ef48342d-7c96-5e48-aba6-339d843840ce',
  '1c0029c1-731f-502a-9675-1dc2648d2c96',
  '9e994a76-dc67-5174-85c4-058ad920c866',
  '3c5458f3-1bbf-574d-9b6a-8e6401d64850',
  '15eb7be1-08db-5296-80a2-1b0b1e749610',
  '0e7c3227-9b90-51fa-a23f-61b4e181b76b',
  '261af659-ae97-576b-8153-56b55c4537cc',
  'dba4b785-4403-5375-98bb-5d4502a14496',
  '24ea0c30-295f-5481-9698-6a3a25e1754b',
  '3c5458f3-1bbf-574d-9b6a-8e6401d64850',
  '9e994a76-dc67-5174-85c4-058ad920c866',
  '6a6946fa-d29f-50e8-b746-25e976cdee90',
  '45ba2dae-9b5f-58e7-b0fd-3632af077529',
  '175596a3-79f3-5fbc-9a42-ecb31d696a0b',
  '8fbb5996-ff8b-546f-a835-ef344d98af55'
];

export function mapGtmToPiwik(gtm_item, extra = {}) {
  if (!gtm_item) return extra;

  return {
    sku: gtm_item.item_id,
    name: gtm_item.item_name,
    category: [gtm_item.item_category],
    price: gtm_item.price,
    brand: gtm_item.item_brand,
    variant: gtm_item.item_variant,
    ...extra
  };
}
