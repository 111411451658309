import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _50041428 = () => interopDefault(import('../pages/resend-booking.vue' /* webpackChunkName: "pages/resend-booking" */))
const _4bbc3347 = () => interopDefault(import('../components/Pages/Checkout/index.vue' /* webpackChunkName: "" */))
const _7668a730 = () => interopDefault(import('../pages/event-attendee-registration.vue' /* webpackChunkName: "pages/event-attendee-registration" */))
const _3ca78eaa = () => interopDefault(import('../components/Pages/CategoryPage.vue' /* webpackChunkName: "" */))
const _bc836fa0 = () => interopDefault(import('../pages/payment-error.vue' /* webpackChunkName: "pages/payment-error" */))
const _f00f5aca = () => interopDefault(import('../pages/payment-processing.vue' /* webpackChunkName: "pages/payment-processing" */))
const _7c1046f2 = () => interopDefault(import('../pages/press.vue' /* webpackChunkName: "pages/press" */))
const _36619354 = () => interopDefault(import('../pages/save-event-form.vue' /* webpackChunkName: "pages/save-event-form" */))
const _79223075 = () => interopDefault(import('../pages/rebooking.vue' /* webpackChunkName: "pages/rebooking" */))
const _5604cd26 = () => interopDefault(import('../components/Pages/GroupEvents/index.vue' /* webpackChunkName: "" */))
const _b4edeb18 = () => interopDefault(import('../components/Pages/Voucher/configurator.vue' /* webpackChunkName: "" */))
const _fbae27fe = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2ee2b310 = () => interopDefault(import('../components/Pages/ProductPage.vue' /* webpackChunkName: "" */))
const _5335ff96 = () => interopDefault(import('../pages/hosts/_slug.vue' /* webpackChunkName: "pages/hosts/_slug" */))
const _99583510 = () => interopDefault(import('../pages/theme.vue' /* webpackChunkName: "" */))
const _22b22b40 = () => interopDefault(import('../pages/venues/_slug.vue' /* webpackChunkName: "pages/venues/_slug" */))
const _4c2a1ace = () => interopDefault(import('../pages/variable-route.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/buchungsbestaetigung-anfordern/",
    component: _50041428,
    pathToRegexpOptions: {"strict":true},
    name: "resend-booking___de"
  }, {
    path: "/checkout/",
    component: _4bbc3347,
    pathToRegexpOptions: {"strict":true},
    name: "checkout___de"
  }, {
    path: "/event-attendee-registration/",
    component: _7668a730,
    pathToRegexpOptions: {"strict":true},
    name: "event-attendee-registration___de"
  }, {
    path: "/events/",
    component: _3ca78eaa,
    meta: {"pageType":"category"},
    props: {"slug":"events"},
    pathToRegexpOptions: {"strict":true},
    name: "events-route___de"
  }, {
    path: "/malen-mit-kindern/",
    component: _3ca78eaa,
    meta: {"pageType":"category"},
    pathToRegexpOptions: {"strict":true},
    name: "themes-route-kids___de"
  }, {
    path: "/motiv/",
    component: _3ca78eaa,
    meta: {"pageType":"category"},
    props: {"slug":"motiv"},
    pathToRegexpOptions: {"strict":true},
    name: "themes-route___de"
  }, {
    path: "/payment-error/",
    component: _bc836fa0,
    pathToRegexpOptions: {"strict":true},
    name: "payment-error___de"
  }, {
    path: "/payment-processing/",
    component: _f00f5aca,
    pathToRegexpOptions: {"strict":true},
    name: "payment-processing___de"
  }, {
    path: "/presse/",
    component: _7c1046f2,
    pathToRegexpOptions: {"strict":true},
    name: "press___de"
  }, {
    path: "/save-event-form/",
    component: _36619354,
    pathToRegexpOptions: {"strict":true},
    name: "save-event-form___de"
  }, {
    path: "/ticketumbuchung/",
    component: _79223075,
    pathToRegexpOptions: {"strict":true},
    name: "rebooking___de"
  }, {
    path: "/gruppenevents/konfigurator/",
    component: _5604cd26,
    pathToRegexpOptions: {"strict":true},
    name: "groupevents-configurator___de"
  }, {
    path: "/malkurs-gutschein/konfigurator/",
    component: _b4edeb18,
    pathToRegexpOptions: {"strict":true},
    name: "voucher-configurator___de"
  }, {
    path: "/",
    component: _fbae27fe,
    pathToRegexpOptions: {"strict":true},
    name: "index___de"
  }, {
    path: "/events/:slug/",
    component: _2ee2b310,
    props: () => {
  const ProductName = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Product/ProductName.vue'));
  const EventImage = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Event/EventImage.vue'));
  const EventDateCity = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Event/EventDateCity.vue'));
  const EventShortInfo = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Event/EventShortInfo.vue'));
  const EventLongInfo = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Event/EventLongInfo.vue'));
  const EventDescription = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Event/EventDescription.vue'));
  const EventHost = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Event/EventHost.vue'));
  const EventVenue = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Event/EventVenue.vue'));
  const AddToCart = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Product/AddToCart.vue'));
  const WebShare = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/UI/molecules/WebShare.vue'));

  const components = ({ props, helpers }) => {
    return {
      left: [
      {
        condition: () => typeof props.theme === 'object',
        module: EventImage,
        props: {
          product: props.product,
          theme: props.theme
        }
      }],

      right: [
      {
        condition: () => true,
        module: ProductName,
        props: {
          product: props.product,
          theme: props.theme
        }
      },
      {
        condition: () => true,
        module: EventDateCity,
        props: {
          event: props.product,
          city: props.category?.value ?? null
        }
      },
      {
        condition: () => !helpers.isProductDisabled(props.product),
        module: EventShortInfo,
        props: {
          product: props.product,
          venue: props.venue,
          host: props.host,
          event: props.event
        }
      },
      {
        condition: () => props.product?.price > 0,
        module: AddToCart,
        props: {
          product: props.product,
          loading: helpers.isAddingToCart.value,
          theme: props.theme
        },
        listeners: {
          addToCart: helpers.addItem
        }
      },
      {
        condition: () => true,
        module: EventLongInfo,
        props: {
          product: props.product,
          event: props.event,
          host: props.host,
          theme: props.theme ?? null
        }
      },
      {
        condition: () => true,
        module: EventDescription,
        props: {
          product: props.product,
          event: props.event,
          theme: props.theme ?? null
        }
      },
      {
        condition: () => true,
        module: EventHost,
        props: {
          host: props.host
        }
      },
      {
        condition: () => true,
        module: EventVenue,
        props: {
          venue: props.venue
        }
      },
      {
        condition: () => true,
        module: WebShare,
        props: {
          product: props.product
        }
      }]
    };
  };

  return {
    components,
    grid: false
  };
},
    pathToRegexpOptions: {"strict":true},
    name: "product-event___de"
  }, {
    path: "/kuenstler/:slug/",
    component: _5335ff96,
    pathToRegexpOptions: {"strict":true},
    name: "hosts-slug___de"
  }, {
    path: "/motiv/:slug-:themeId(\\d+)/",
    component: _99583510,
    props: () => {
  const ProductName = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Product/ProductName.vue'));
  const EventImage = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Event/EventImage.vue'));
  const EventDateCity = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Event/EventDateCity.vue'));
  const EventShortInfo = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Event/EventShortInfo.vue'));
  const EventLongInfo = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Event/EventLongInfo.vue'));
  const EventDescription = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Event/EventDescription.vue'));
  const EventHost = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Event/EventHost.vue'));
  const EventVenue = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Event/EventVenue.vue'));
  const AddToCart = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Product/AddToCart.vue'));
  const WebShare = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/UI/molecules/WebShare.vue'));

  const components = ({ props, helpers }) => {
    return {
      left: [
      {
        condition: () => typeof props.theme === 'object',
        module: EventImage,
        props: {
          product: props.product,
          theme: props.theme
        }
      }],

      right: [
      {
        condition: () => true,
        module: ProductName,
        props: {
          product: props.product,
          theme: props.theme
        }
      },
      {
        condition: () => true,
        module: EventDateCity,
        props: {
          event: props.product,
          city: props.category?.value ?? null
        }
      },
      {
        condition: () => !helpers.isProductDisabled(props.product),
        module: EventShortInfo,
        props: {
          product: props.product,
          venue: props.venue,
          host: props.host,
          event: props.event
        }
      },
      {
        condition: () => props.product?.price > 0,
        module: AddToCart,
        props: {
          product: props.product,
          loading: helpers.isAddingToCart.value,
          theme: props.theme
        },
        listeners: {
          addToCart: helpers.addItem
        }
      },
      {
        condition: () => true,
        module: EventLongInfo,
        props: {
          product: props.product,
          event: props.event,
          host: props.host,
          theme: props.theme ?? null
        }
      },
      {
        condition: () => true,
        module: EventDescription,
        props: {
          product: props.product,
          event: props.event,
          theme: props.theme ?? null
        }
      },
      {
        condition: () => true,
        module: EventHost,
        props: {
          host: props.host
        }
      },
      {
        condition: () => true,
        module: EventVenue,
        props: {
          venue: props.venue
        }
      },
      {
        condition: () => true,
        module: WebShare,
        props: {
          product: props.product
        }
      }]
    };
  };

  return {
    components,
    grid: false
  };
},
    pathToRegexpOptions: {"strict":true},
    name: "theme___de"
  }, {
    path: "/products/:slug/",
    component: _2ee2b310,
    props: () => {
  const EventImage = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Event/EventImage.vue'));
  const ProductDescription = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Product/ProductDescription.vue'));
  const ProductName = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Product/ProductName.vue'));
  const AddToCart = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/Product/AddToCart.vue'));
  const WebShare = () => Promise.resolve().then(() => require('@odyssey/realtainment-theme/components/UI/molecules/WebShare.vue'));
  const components = ({ props, helpers }) => {
    return {
      left: [
      {
        module: EventImage,
        props: {
          product: props.product
        }
      }],

      right: [
      {
        module: ProductName,
        props: {
          product: props.product,
          showSubtitle: false
        }
      },
      {
        module: ProductDescription,
        props: {
          product: props.product,
          cssFlags: ['is-paddingless']
        }
      },
      {
        condition: () => props.product?.price > 0,
        module: AddToCart,
        props: {
          product: props.product,
          loading: helpers.isAddingToCart.value
        },
        listeners: {
          addToCart: helpers.addItem
        }
      },
      {
        condition: () => true,
        module: WebShare,
        props: {
          product: props.product
        }
      }]
    };
  };

  return {
    components,
    grid: false
  };
},
    pathToRegexpOptions: {"strict":true},
    name: "product-simple___de"
  }, {
    path: "/venue/:slug/",
    component: _22b22b40,
    pathToRegexpOptions: {"strict":true},
    name: "venues-slug___de"
  }, {
    path: "/:slug(.*)/",
    component: _4c2a1ace,
    pathToRegexpOptions: {"strict":true},
    name: "variable-route___de"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
