import { sharedRef, useVSFContext, Logger } from '@odyssey/core';
import { computed } from '@nuxtjs/composition-api';
const PRODUCT_GIFTCARD_TYPE = 'giftcard';
const PRODUCT_BUNDLE_TYPE = 'product_bundle';

export const useGiftcard = (): any => {
  const { $sylius } = useVSFContext();
  const data = sharedRef([], 'useGiftcard--products');
  const loading = sharedRef(null, 'useGiftcard--loading');
  const error = sharedRef(null, 'useGiftcard--error');

  const search = async (params): Promise<void> => {
    try {
      loading.value = true;
      const { products } = await $sylius.api.getProduct(params, {
        productsQuery: 'productsQuery'
      });
      data.value = products;
      error.value = null;
    } catch (err) {
      error.value = err;
      Logger.error('useGiftcard/search', err);
    } finally {
      loading.value = false;
    }
  };

  const giftcardsProducts = computed(() => data.value.filter(p => p.typeId === PRODUCT_GIFTCARD_TYPE && p.enabled));
  // @todo add check if configurableChildren have giftcards (currently is always empty, needs to be fixed on Sylius side)
  const multiTicketsProducts = computed(() => data.value.filter(p => p.typeId === PRODUCT_BUNDLE_TYPE && p.enabled));
  const giftcardsAmountOptions = computed(() => giftcardsProducts.value.reduce((acc, product) => {
    const price = product.price;

    if (acc[price]) acc[price].push(product);
    else acc[price] = [product];

    return acc;
  }, {}));

  return {
    search,
    loading,
    error,
    giftcardsProducts,
    multiTicketsProducts,
    giftcardsAmountOptions,
    products: computed(() => data.value)
  };
};
