//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'EventLongInfo',
  components: {
    UsersIcon: () =>
      import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon--users.svg?inline'),
    GiftIcon: () =>
      import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon--gift.svg?inline'),
    EventFeatures: () => import(/* webpackChunkName: "chunk-page-event" */ '@/components/Event/EventFeatures')
  },
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {}
    },
    theme: {
      type: Object,
      default: () => {}
    },
    host: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    function setSelectedTheme() {
      if (props.theme) {
        localStorage.setItem('selected-theme', JSON.stringify(props.theme.uuid));
      }
    }

    return {
      setSelectedTheme
    };
  }
};
