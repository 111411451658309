import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext, Logger } from '@odyssey/core';
import { FlexPageController } from '@odyssey/realtainment-api';
export const useStoryblokGlobalComponents = (): any => {
  const context = useVSFContext();
  const globalComponents = sharedRef({
    header: {},
    footer: {},
    notificationBars: {},
    press: {},
    inspirations: {}
  }, 'useStoryblokGlobalComponents');

  const loading = sharedRef(false, 'useStoryblokGlobalComponents-loading');
  const error = sharedRef({
    globalComponents: null
  }, 'useStoryblokGlobalComponents-error');

  const fetchPress = async () => {
    try {
      globalComponents.value = {
        ...globalComponents.value,
        press: await context.$storyblok.api.getStoryblokPressPage()
      };
    } catch (err) {
      Logger.error('useStoryblokGlobalComponents/fetch', err);
    }
  };

  const fetch = async (params): Promise<void> => {
    Logger.debug('useStoryblokGlobalComponents/fetch', params);

    try {
      loading.value = true;
      globalComponents.value = await context.$storyblok.api.getStoryblokGlobalComponents(params);
      error.value.globalComponents = null;
    } catch (err) {
      error.value.globalComponents = err;
      Logger.error('useStoryblokGlobalComponents/fetch', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    fetch,
    fetchPress,
    globalComponents: computed(() => globalComponents.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value)
  };
};

export const useStoryblokPage = (): any => {
  const context = useVSFContext();
  const pageData = sharedRef(null, 'useStoryblokPage');

  const loading = sharedRef(false, 'useStoryblokPage-loading');
  const error = sharedRef({
    pageData: null
  }, 'useStoryblokPage-error');

  const fetchStoryblokPage = async (slug: string) => {
    Logger.debug('useStoryblokPage/fetch', slug);
    loading.value = true;
    const version = context.$config.server.isProduction ? 'published' : 'draft';
    const data = await context.$storyblok.api.getStoryblokFlexPage({ slug, version });
    if (data?.content) {
      pageData.value = {
        ...data,
        content: FlexPageController.default(
          data.content,
          context.$storyblok
        )
      };
      error.value.pageData = null;
    }
    loading.value = false;
  };

  return {
    fetchStoryblokPage,
    pageData: computed(() => pageData.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value)
  };
};
