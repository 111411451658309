import { Context, Logger, useCartFactory, UseCartFactoryParams } from '@odyssey/core';
import type { Cart, CartItem, CartLineItem, Product } from '@odyssey/realtainment-api';
import { prepareFilterArgument } from '../helpers';
import { PRODUCT_EVENT_TYPE, PRODUCT_ONLINE_EVENT_TYPE } from '../index';

interface CartProduct extends Product {
  product_option?: any;
}

const getCart = async (context: Context, cartId: string, token: string) => {
  try {
    const cart = await context.$rest.api.getCart({ cartId, token });

    if (Array.isArray(cart.items) && cart.items.length) {
      cart.themes = [];

      const skus = cart.items.map((item) => item.sku);

      const { products } = await context.$sylius.api.getProduct(
        {
          skus
        },
        {
          productsQuery: 'productsQueryBySku'
        }
      );

      const eventThemeUuids = [];
      if (products.length) {
        cart.items = cart.items.map((cartItem) => {
          const product = products.find((p) => p.sku === cartItem.sku);
          if (
            product &&
            product.eventThemeUuid &&
            [PRODUCT_EVENT_TYPE, PRODUCT_ONLINE_EVENT_TYPE].includes(cartItem.product_type)
          ) {
            eventThemeUuids.push(product.eventThemeUuid);
          }
          return {
            ...cartItem,
            product
          };
        });
      }

      if (eventThemeUuids.length) {
        const filter = prepareFilterArgument('uuid', eventThemeUuids, []);

        const themes = await context.$pimcore.api.getThemes({ filter });
        cart.themes = themes.reduce((map, theme) => {
          map[theme.uuid] = theme;
          return map;
        }, {});
      }
    }

    return cart;
  } catch (e) {
    console.error('error in getCart useCart', e);
    return null;
  }
};

const params: UseCartFactoryParams<Cart, CartLineItem, CartProduct> = {
  load: async (context: Context) => {
    const apiState = context.$sylius.config.state;
    let cartId = apiState.getCartId();
    // user auth token
    const token = '';
    try {
      // create new cart object in the backend
      if (!cartId) {
        cartId = await context.$rest.api.createCart();
        apiState.setCartId(cartId);
      }
      const result = await getCart(context, cartId, token);
      // empty response means cart token is no longer valid
      if (result === undefined || (result && Object.keys(result).length === 0)) {
        cartId = await context.$rest.api.createCart();
        apiState.setCartId(cartId);
        return await getCart(context, cartId, token);
      }
      return result;
    } catch (e) {
      Logger.error('useCart/load', e);
      apiState.setCartId(null);
    }
  },
  addItem: async (context: Context, { product, quantity, currentCart }) => {
    const apiState = context.$sylius.config.state;
    // user auth token
    const token = '';
    let cartId = apiState.getCartId();

    if (!cartId) {
      await params.load(context, {});
      cartId = apiState.getCartId();
    }

    try {
      const existingItem = null;
      if (currentCart?.items) {
        currentCart.items.find((item) => item.sku === product.sku);
      }

      const cartItem: any = {
        sku: product.sku,
        quoteId: cartId,
        qty: existingItem ? existingItem.qty + quantity : quantity
      };

      if (product?.product_option !== undefined) {
        cartItem.product_option = product.product_option;
        cartItem.item_id = parseInt(String(product.id).replace('/api/v2/products/', ''));
      }

      await context.$rest.api.updateCart({
        cartId,
        token,
        product: {
          cartItem
        }
      });
      return await getCart(context, cartId, token);
    } catch (e) {
      Logger.error('useCart/addItem', e);

      cartId = await context.$rest.api.createCart();
      apiState.setCartId(cartId);
      return await params.addItem(context, { product, quantity, currentCart });

    }
  },
  removeItem: async (context: Context, { product, customQuery }) => {
    const apiState = context.$sylius.config.state;
    const cartId = apiState.getCartId();
    const cartItem = product as CartLineItem;
    // user auth token
    const token = '';
    try {
      await context.$rest.api.removeItemFromCart({
        cartId,
        token,
        product: {
          cartItem: {
            sku: cartItem.sku,
            quoteId: cartId,
            item_id: cartItem._id
          }
        }
      });
      return await getCart(context, cartId, token);
    } catch (e) {
      Logger.error('useCart/removeItem', e);
    }
  },
  updateItemQty: async (context: Context, { product, quantity, customQuery }) => {
    const apiState = context.$sylius.config.state;
    const cartId = apiState.getCartId();
    const cartItem = product as CartLineItem;
    // user auth token
    const token = '';

    try {
      await context.$rest.api.updateCart({
        cartId,
        token,
        product: {
          cartItem: {
            sku: cartItem.sku,
            quoteId: cartId,
            item_id: cartItem._id,
            qty: quantity
          }
        }
      });
      return await getCart(context, cartId, token);
    } catch (e) {
      Logger.error('useCart/updateItemQty', e);
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  clear: async (context: Context) => {
    // user auth token
    const token = '';
    const apiState = context.$sylius.config.state;
    apiState.setCartId(null);

    const cartId = await context.$rest.api.createCart();
    apiState.setCartId(cartId);
    return await getCart(context, cartId, token);
  },
  // @todo
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  applyCoupon: async (context: Context, { currentCart, couponCode, customQuery }) => {
    // user auth token
    const token = '';
    const apiState = context.$sylius.config.state;
    const cartId = apiState.getCartId();
    await context.$rest.api.applyDiscount({ cartId, coupon: couponCode, token });
    return {
      updatedCart: await getCart(context, cartId, token),
      updatedCoupon: couponCode
    };
  },

  // @todo
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeCoupon: async (context: Context, { currentCart, customQuery, couponCode }) => {
    // user auth token
    const token = '';
    const apiState = context.$sylius.config.state;
    const cartId = apiState.getCartId();

    const giftcardMatch = (currentCart as any).giftcards?.find(
      (item) => item?.code === couponCode || item?.couponCode === couponCode
    );
    try {
      if (giftcardMatch) {
        await context.$rest.api.removeGiftcard({ cartId, token, giftCardCode: couponCode });
      } else {
        await context.$rest.api.removeCoupon({ cartId, token });
      }
      return {
        updatedCart: await getCart(context, cartId, token)
      };
    } catch (e) {
      Logger.error(e);
    }
  },
  // @todo
  isInCart: (context: Context, { currentCart, product }) => {
    // if (currentCart?.items) {
    //   const productCheck = currentCart.items.filter(p => product.selectedVariant.code === p.variant.code);
    //   return productCheck.length > 0;
    // }

    return false;
  }
};

export * from './cartGetters';
export const useCart = useCartFactory<Cart, CartItem, CartProduct>(params);
