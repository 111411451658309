import Vue from 'vue';

Vue.directive('focus', {
  bind(el) {
    el._mouseHandler = function () {
      el.style.outline = 'none';
    };
    el._keyHandler = function () {
      el.style.outline = '';
      el.focus();
    };
    window.addEventListener('mousedown', el._mouseHandler, {
      passive: true
    });
    el.addEventListener('keyup', el._keyHandler, {
      passive: true
    });
  },
  unbind(el) {
    window.removeEventListener('mousedown', el._mouseHandler);
    el.removeEventListener('keyup', el._keyHandler);
  }
});
