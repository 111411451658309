import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext, Logger } from '@odyssey/core';
import { removeDuplicatesByKey, prepareFilterArgument } from '../helpers';
export * from './getters';

export const useVenue = (): any => {
  const { $pimcore } = useVSFContext();

  const venue = sharedRef(null, 'useVenue--venue');
  const data = sharedRef([], 'useVenue--data');
  const loading = sharedRef(null, 'useVenue--loading');
  const error = sharedRef(null, 'useVenue--error');

  const addToData = (venues, key = 'uuid') => {
    data.value = removeDuplicatesByKey([...data.value, ...venues], key);
  };

  const searchOne = async (venueId: number): Promise<void> => {
    Logger.debug('useVenue/searchOne', venueId);
    try {
      loading.value = true;
      venue.value = await $pimcore.api.getVenue({ venueId });
      error.value = null;
    } catch (err) {
      error.value = err;
      Logger.error('useVenue/searchOne', err);
    } finally {
      loading.value = false;
    }
  };

  const search = async (venueUuid: string | Array<string>): Promise<void> => {
    if (venueUuid === null) return;

    const filter = prepareFilterArgument('uuid', venueUuid, data.value);

    try {
      loading.value = true;
      // @todo prevent fetching again previously fethed venues
      const venue = await $pimcore.api.getVenues({ filter });
      addToData(venue);
      error.value = null;
    } catch (err) {
      error.value = err;
      Logger.error('useVenue/search', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    search,
    searchOne,
    addToData,
    loading,
    error,
    result: computed(() => data.value),
    venue: computed(() => venue.value)
  };
};
