//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { useContext } from '@nuxtjs/composition-api';
import { PRODUCT_EVENT_TYPE } from '@/helpers';
export default {
  name: 'EventDescription',
  components: {
    TransitionExpand: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/TransitionExpand')
  },
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {}
    },
    theme: {
      type: Object,
      default: () => {}
    },
    event: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const context = useContext();

    return {
      context,
      PRODUCT_EVENT_TYPE
    };
  }
};
