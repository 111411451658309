import { GTMReturn, GTMInput } from '../types';

export const amountSelect = (payload: GTMInput): GTMReturn => ({
  event: 'giftcard_amount_select',
  eventInfo: {
    detail: 'select gift card amount'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: {
    giftcardAmount: payload.amount,
    currency: payload.currency
  }
});

export const messageSelect = (payload: GTMInput): GTMReturn => ({
  event: 'giftcard_message_select',
  eventInfo: {
    detail: 'choose a gift card message'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: {
    message: payload.message,
    messsageType: payload.message_type

  }
});

export const messageOpen = (): GTMReturn => ({
  event: 'giftcard_message_open',
  eventInfo: {
    detail: 'open gift card message dropdown'
  },
  category: {
    primaryCategory: 'interaction'
  }
});

export const goBackStep = (): GTMReturn => ({
  event: 'giftcard_back',
  eventInfo: {
    detail: 'gift card back'
  },
  category: {
    primaryCategory: 'interaction'
  }
});

export const themeSelect = (payload: GTMInput): GTMReturn => ({
  event: 'giftcard_motiv_select',
  eventInfo: {
    detail: 'select an giftcard'
  },
  category: {
    primaryCategory: 'ecommerce'
  },
  attributes: {
    location: payload.location,
    currency: payload.currency
  },
  theme: payload.theme
});
