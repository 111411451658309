import {
  footerClick,
  miniCartOpen,
  newsletterError,
  newsletterSingUp,
  pageLoad,
  promotionDismiss,
  promotionImpression,
  promotionSelect,
  continueShopping,
  proceedToPurchase
} from './sections/all';
import {
  citySelectionInputStart,
  citySelectionOpen,
  citySelectionSubmit,
  eventImpression,
  eventSelect,
  eventShowMore,
  filterApply,
  filterOpen,
  filterReset
} from './sections/listing';
import {
  addToCart,
  eventLocationView,
  eventView,
  motivView,
  moreDatesOpen,
  moreDatesSelect,
  quantityOpen,
  quantitySelect
} from './sections/detail';
import {
  addCouponError,
  addCouponOpen,
  addCouponSubmit,
  addCouponSuccess,
  addPaymentInfo,
  purchase,
  purchaseSubmit,
  purchaseSubmitError,
  removeFromCart,
  corporateOrderToggle,
  beginCheckout,
  purchaseError,
  purchasePaymentFailed,
  purchasePaymentPending,
  returnToCartClicked
} from './sections/checkout';
import { amountSelect, goBackStep, messageOpen, messageSelect, themeSelect } from './sections/giftcard';
import {
  pageLocation,
  pageDate,
  pageTheme,
  pageCart,
  eventTheme,
  purchaseB2B,
  eventOrderQuantity
} from './sections/b2b';
import {
  citySelectionModalOpen,
  citySelectionModalSubmit,
  locationModalShown,
  locationModalClosed,
  locationModalDismissed
} from './sections/modal';

export const mapGtm = {
  'all/PAGE_LOAD': pageLoad,
  'all/FOOTER_CLICK': footerClick,
  'all/MINICART_OPEN': miniCartOpen,
  'all/PROMOTION_IMPRESSION': promotionImpression,
  'all/PROMOTION_SELECT': promotionSelect,
  'all/PROMOTION_DISMISS': promotionDismiss,
  'all/NEWSLETTER_SIGN_UP': newsletterSingUp,
  'all/NEWSLETTER_ERROR': newsletterError,
  'all/CONTINUE_SHOPPING': continueShopping,
  'all/PROCEED_TO_CHECKOUT': proceedToPurchase,
  'listing/FILTER_APPLY': filterApply,
  'listing/FILTER_OPEN': filterOpen,
  'listing/FILTER_RESET': filterReset,
  'listing/EVENT_SHOW_MORE': eventShowMore,
  'listing/EVENT_SELECT': eventSelect,
  'listing/EVENT_IMPRESSION': eventImpression,
  'listing/CITY_PANEL_OPEN': citySelectionOpen,
  'listing/CITY_PANEL_INPUT_FOCUS': citySelectionInputStart,
  'listing/CITY_PANEL_INPUT_SUBMIT': citySelectionSubmit,
  'detail/EVENT_VIEW': eventView,
  'detail/MOTIV_VIEW': motivView,
  'detail/PRODUCT_MORE_DATES_SELECT': moreDatesSelect,
  'detail/PRODUCT_MORE_DATES_OPEN': moreDatesOpen,
  'detail/PRODUCT_ADD_TO_CART': addToCart,
  'detail/QUANTITY_OPEN': quantityOpen,
  'detail/QUANTITY_SELECT': quantitySelect,
  'detail/EVENT_LOCATION_VIEW': eventLocationView,
  'checkout/BEGIN': beginCheckout,
  'checkout/CORPORATE_ORDER': corporateOrderToggle,
  'checkout/ADD_PAYMENT_INFO': addPaymentInfo,
  'checkout/B2B_PURCHASE': purchaseB2B,
  'checkout/PURCHASE': purchase,
  'checkout/PURCHASE_SUBMIT': purchaseSubmit,
  'checkout/PURCHASE_SUBMIT_ERROR': purchaseSubmitError,
  'checkout/PRODUCT_REMOVE_FROM_CART': removeFromCart,
  'checkout/ADD_COUPON_OPEN': addCouponOpen,
  'checkout/ADD_COUPON_SUBMIT': addCouponSubmit,
  'checkout/ADD_COUPON_SUCCESS': addCouponSuccess,
  'checkout/ADD_COUPON_ERROR': addCouponError,
  'checkout/PURCHASE_ERROR': purchaseError,
  'checkout/PURCHASE_PAYMENT_FAILED': purchasePaymentFailed,
  'checkout/PURCHASE_PAYMENT_PENDING': purchasePaymentPending,
  'checkout/RETURN_TO_CART_CLICKED': returnToCartClicked,
  'giftcard/AMOUNT_SELECT': amountSelect,
  'giftcard/MESSAGE_OPEN': messageOpen,
  'giftcard/MESSAGE_SELECT': messageSelect,
  'giftcard/THEME_SELECT': themeSelect,
  'giftcard/GO_BACK_STEP': goBackStep,
  'b2b/PAGE_DATE': pageDate,
  'b2b/PAGE_LOCATION': pageLocation,
  'b2b/PAGE_THEME': pageTheme,
  'b2b/PAGE_CART': pageCart,
  'b2b/EVENT_THEME': eventTheme,
  'b2b/EVENT_ORDER_QUANTITY': eventOrderQuantity,
  'modal/CITY_SELECTION_MODAL_OPEN': citySelectionModalOpen,
  'modal/CITY_SELECTION_MODAL_SUBMIT': citySelectionModalSubmit,
  'modal/LOCATION_MODAL_SHOWN': locationModalShown,
  'modal/LOCATION_MODAL_CLOSED': locationModalClosed,
  'modal/LOCATION_MODAL_DISMISSED': locationModalDismissed
};
