//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref, onMounted, computed, useContext } from '@nuxtjs/composition-api';
import { venueGetters } from '@odyssey/realtainment';
import { Flicking } from '@egjs/vue-flicking';
import { Pagination } from '@egjs/flicking-plugins';

import '@egjs/vue-flicking/dist/flicking.css';
import '@egjs/flicking-plugins/dist/pagination.css';

export default {
  name: 'EventVenue',
  components: {
    Flicking,
    NuxtTransformImage: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/NuxtTransformImage')
  },
  props: {
    venue: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const context = useContext();

    const plugins = ref([]);

    const options = {
      align: 'center',
      defaultIndex: 0,
      bound: true,
      deceleration: 0.0005,
      hideBeforeInit: true,
      preventEventsBeforeInit: true,
      moveType: ['strict', { count: 1 }],
      panelsPerView: 1
    };
    onMounted(() => {
      plugins.value = [new Pagination({ type: 'bullet' })];
    });
    const venueImageCarousel = ref(null);
    const venueImageCarouselSettings = {
      wheelControl: false,
      shortDrag: false,
      breakpoints: {
        0: {
          itemsToShow: 1,
          centerMode: true
        }
      }
    };

    const venueImageCarouselSlides = computed(() => [
      {
        image: venueGetters.getImage(context, props.venue)
      }
    ]);

    return {
      venueGetters,
      context,
      venueImageCarousel,
      venueImageCarouselSettings,
      venueImageCarouselSlides,
      plugins,
      options
    };
  }
};
