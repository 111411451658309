import StoryblokClient from '@odyssey/realtainment-api/src/storyblok/client';
import componentParser from './component-parser';
import { parse as parseDate } from 'date-fns';

export default function (
  {
    _editable,
    _uid,
    bannerSize,
    content,
    background,
    link,
    trackingId,
    endDate,
    button,
    buttonPosition,
    opacity,
    textAlign,
    textJustify
  },
  context
) {
  return {
    module: 'SGeneralBanner',
    _uid,
    _editable,
    bannerSize,
    content: content?.map((component) => ({
      ...component,
      text: StoryblokClient(context)?.richTextResolver?.render(component?.text)
    })),
    background,
    link,
    trackingId,
    endDate,
    button: button.length ? button?.map((component) => componentParser(component, context)) : [],
    buttonPosition,
    opacity,
    textAlign,
    textJustify
  };
}
