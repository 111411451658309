export default function ({
  _editable,
  _uid,
  content,
  personName,
  personImage,
  personPosition,
  mode,
  isCard,
  withIcon,
  link,
  id
}) {
  return {
    module: 'SCarouselTestimonial',
    _uid,
    _editable,
    content,
    personName,
    personImage,
    personPosition,
    mode,
    isCard,
    withIcon,
    link,
    id
  };
}
