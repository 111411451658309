import componentParser from './component-parser';

export default function (
  { image, imageMobile, cssFlags, headline, responsiveHeight, contentComponent, alignItems, _editable, _uid },
  context
) {
  let respHeight = responsiveHeight?.options || undefined;
  if (Array.isArray(respHeight)) {
    respHeight = respHeight.reduce((acc, curr) => {
      acc[curr.key] = curr.value;
      return acc;
    }, {});
  }
  return {
    module: 'SHero',
    _uid,
    _editable,
    image: image?.filename,
    imageMobile: imageMobile?.filename,
    cssFlags,
    headline,
    alignItems,
    responsiveHeight: respHeight,
    components: contentComponent?.map((component) => componentParser(component, context))
  };
}
