//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, onMounted, ref, nextTick, useContext } from '@nuxtjs/composition-api';
import { useEvent, useProduct } from '@odyssey/realtainment';
import { useCategory } from '@odyssey/realtainment';
import uniq from 'lodash/uniq';
import { prepareProducts } from '@/helpers';
import { format, parse } from 'date-fns';
import de from 'date-fns/locale/de';
import { PRODUCT_EVENT_TYPE, isProductDisabled } from '@/helpers';
export default {
  name: 'EventDateCity',
  components: {
    Spinner: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/Spinner'),
    CityAndDateSelect: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-selects" */ '@/components/UI/molecules/selects/CityAndDateSelect')
  },
  props: {
    event: {
      type: Object,
      required: true,
      default: () => {}
    },
    city: {
      type: Object,
      default: () => null
    }
  },
  setup(props) {
    const context = useContext();
    const { categories } = useCategory();
    const { products: similarProducts, search: searchSimilarProducts, loading } = useProduct('similarProducts');
    const { searchData } = useEvent('event');
    const cityAndDateSelectionModalStepStartIndex = ref(0);
    const shouldShowCityAndDateSelectionModal = ref(false);
    const selectedCity = ref(props.city);

    const date = computed(() =>
      props.event?.event_date?.string
        ? format(parse(props.event.event_date.string, 'yyyy-MM-dd k:mm', new Date()), 'EEEEEE, d. LLLL yyyy', {
            locale: de
          })
        : null
    );

    const availableCities = computed(() => {
      const cityCache = [];
      return similarProducts.value?.products
        ?.reduce((acc, curr) => {
          const city = categories.value.find((cat) => cat.slug === curr.categorySlug);
          if (city && !cityCache.includes(city.slug)) {
            cityCache.push(city.slug);
            acc.push(city);
          }
          return acc;
        }, [])
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
    });

    const availableDatesInCity = computed(() => {
      return prepareProducts(
        similarProducts.value?.products?.filter(
          (product) =>
            (selectedCity.value === null && product.sku !== props.event.sku) ||
            (product.categorySlug === selectedCity.value?.slug && product.sku !== props.event.sku)
        ) || [],
        context
      );
    });

    const fetchSimilarProducts = async () => {
      await searchSimilarProducts({
        themeUuid: props.event.eventThemeUuid ?? props.event.uuid
      });

      if (similarProducts.value?.products) {
        const fetchedProducts = similarProducts.value.products;

        const eventHostUuids = uniq(fetchedProducts.map((p) => p.hostUuid));
        const eventVenueUuids = uniq(fetchedProducts.map((p) => p.venueUuid));
        const eventThemeUuids = uniq(fetchedProducts.map((p) => p.eventThemeUuid));

        await searchData({
          filter_host: eventHostUuids,
          filter_venue: eventVenueUuids,
          filter_theme: eventThemeUuids
        });
      }
    };

    const showCityAndDateSelectionModal = (startAt = 0) => {
      cityAndDateSelectionModalStepStartIndex.value = startAt;
      shouldShowCityAndDateSelectionModal.value = true;
    };

    const closeCityAndDateSelectionModal = () => {
      shouldShowCityAndDateSelectionModal.value = false;
    };

    const selectCity = async (city, modalOpen = false) => {
      selectedCity.value = city;
      if (modalOpen) {
        closeCityAndDateSelectionModal();
        await nextTick();
        showCityAndDateSelectionModal(1);
      }
    };

    onMounted(async () => {
      if (props.event?.eventThemeUuid || props.event?.uuid) {
        await fetchSimilarProducts();
      }
    });

    return {
      cityAndDateSelectionModalStepStartIndex,
      shouldShowCityAndDateSelectionModal,
      showCityAndDateSelectionModal,
      closeCityAndDateSelectionModal,
      date,
      selectCity,
      selectedCity,
      PRODUCT_EVENT_TYPE,
      isProductDisabled,
      availableCities,
      availableDatesInCity,
      loading
    };
  }
};
