import StoryblokClient from '@odyssey/realtainment-api/src/storyblok/client';

export default function ({ items, _editable, _uid, title, description, arrows, horizontalAlign }, context) {
  return {
    _editable,
    _uid,
    module: 'SFaq',
    title,
    description: StoryblokClient(context)?.richTextResolver?.render(description),
    items: items.map(item => ({
      title: item?.title,
      content: StoryblokClient(context)?.richTextResolver?.render(item?.content)
    })),
    arrows,
    horizontalAlign
  };
}
