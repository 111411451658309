export default async ({ res, route, i18n }) => {
  if (!process.server) return;

  const locale = i18n.localeProperties.code;
  const excludedRoutes = [
    `rebooking___${locale}`,
    `resend-booking___${locale}`,
    `checkout___${locale}`,
    `payment-processing___${locale}`
  ];

  if (!excludedRoutes.includes(route.name)) {
    // 1 month in ms
    res.setHeader('Cache-Control', 'public, max-age=2629800000');
  }
};
