import { sharedRef, useVSFContext, Logger } from '@odyssey/core';
import { computed } from '@nuxtjs/composition-api';

export const useGroupProduct = (): any => {
  const { $syliusGroupBooking } = useVSFContext();
  const cities = sharedRef([], 'useGroupProducts--cities');
  const groupProducts = sharedRef([], 'useGroupProducts--products');
  const paginationInfo = sharedRef([], 'useGroupProducts--pagination');
  const loading = sharedRef(null, 'useGroupProducts--loading');
  const error = sharedRef(null, 'useGroupProducts--error');

  const search = async (params, query): Promise<void> => {
    try {
      loading.value = true;

      if (query.includes('groupCities')) {
        const categories = await $syliusGroupBooking.api.getCategory(params);
        cities.value = categories.filter((category) => category.productCount > 0);
      } else {
        const { products, pagination } = await $syliusGroupBooking.api.getProduct(params, { productsQuery: query });

        groupProducts.value = products
          .filter(product => product.eventThemeUuid)
          .map(product => {
            product.eventThemeUuid = product.eventThemeUuid.replace(`_${product.categoryName}`, '');
            return product;
          });

        paginationInfo.value = pagination;
      }
      error.value = null;
    } catch (err) {
      error.value = err;
      Logger.error('useGroupProducts/search', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    search,
    cities: computed(() => cities.value),
    products: computed(() => groupProducts.value),
    loading: computed(() => loading.value),
    paginationInfo: computed(() => paginationInfo.value)
  };
};
