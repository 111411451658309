import StoryblokClient from '@odyssey/realtainment-api/src/storyblok/client';

export default function (
  { _uid, _editable, text, texthadow, fontSizeMobile, fontSizeTablet, fontSizeDesktop },
  context
) {
  return {
    module: 'SBannerContent',
    _uid,
    _editable,
    text: StoryblokClient(context)?.richTextResolver?.render(text),
    texthadow,
    fontSizeMobile,
    fontSizeTablet,
    fontSizeDesktop
  };
}
