import { GTMReturn, GTMInput } from '../types';

export const citySelectionModalOpen = (payload: GTMInput): GTMReturn => ({
  event: 'city_selection_modal_open',
  eventInfo: {
    detail: 'Tracking on selection dropdown'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: {
    location: payload.location
  }
});

export const citySelectionModalSubmit = (payload: GTMInput): GTMReturn => ({
  event: 'city_selection_modal_submit',
  eventInfo: {
    detail: 'Tracking on selection dropdown'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: {
    location: payload.location,
    selection: payload.selection
  }
});

export const locationModalShown = (payload: GTMInput): GTMReturn => ({
  event: 'location_modal_shown',
  eventInfo: {
    detail: 'Tracking on modal view'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: {
    location: payload.location
  }
});

export const locationModalClosed = (payload: GTMInput): GTMReturn => ({
  event: 'location_modal_closed',
  eventInfo: {
    detail: 'Tracking on modal close icon/navigate away'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: {
    location: payload.location
  }
});

export const locationModalDismissed = (payload: GTMInput): GTMReturn => ({
  event: 'location_modal_dismissed',
  eventInfo: {
    detail: 'Tracking on Later dismiss link'
  },
  category: {
    primaryCategory: 'interaction'
  },
  attributes: {
    location: payload.location
  }
});
