import { GTMReturn, GTMInput } from '../types';
export const pageLocation = (payload: GTMInput): GTMReturn => ({
  event: 'event_location_groups',
  attributes: payload.attributes
});

export const pageDate = (payload: GTMInput): GTMReturn => ({
  event: 'event_time_groups',
  attributes: payload.attributes
});

export const pageTheme = (payload: GTMInput): GTMReturn => ({
  event: 'theme_select_groups',
  attributes: payload.attributes
});

export const pageCart = (payload: GTMInput): GTMReturn => ({
  event: 'add_to_cart_groups',
  category: {
    primaryCategory: 'ecommerce'
  },
  attributes: {
    currency: payload.currency
  },
  items: payload.items,
  _clear: true
});

export const purchaseB2B = (payload: GTMInput): GTMReturn => ({
  event: 'purchase_groups',
  eventInfo: {
    detail: 'purchase made'
  },
  category: {
    primaryCategory: 'ecommerce'
  },
  attributes: {
    payment_type: payload.payment_type,
    corporate_order: payload.corporate_order
  },
  transaction: payload.transaction,
  items: payload.items,
  _clear: true
});

export const eventOrderQuantity = ({ quantity }: { quantity: number }): GTMReturn => ({
  event: 'event_orderquantity_groups',
  eventInfo: { detail: 'total amount of participants' },
  attributes: {
    quantity
  }
});

export const eventTheme = ({ theme }: { theme: string }): GTMReturn => ({
  event: 'event_theme_groups',
  eventInfo: { detail: 'the theme that the user has selected' },
  attributes: { theme }
});
