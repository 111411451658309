import s_section_column from './s-section-column';
import s_button from './s-button';
import s_faq from './s-faq';
import s_content from './s-content';
import s_headline from './s-headline';
import s_list from './s-list';
import s_picture from './s-picture';
import s_tile from './s-tile';
import s_anchor from './s-anchor';
import s_video_embed from './s-video-embed';
import s_event_grid from './s-event-grid';
import s_tabbed_event_grid from './s-tabbed-event-grid';
import s_filtered_event_grid from './s-filtered-event-grid';
import s_newsletter_banner from './s-newsletter-banner';
import s_logo_row from './s-logo-row';

import s_general_banner from './s-general-banner';
import s_separator from './s-separator';
import s_html from './s-html';

import s_carousel_general_layout from './s-carousel-general-layout';
import s_founder from './s-founder';
import s_reviews from './s-reviews';
import s_team_event from './s-team-event';
import s_voucher_header from './s-voucher-header';

import s_carousel_item_voucher_category from './s-carousel-item-voucher-category';
import s_carousel_item_general_layout from './s-carousel-item-general-layout';
import s_carousel_item_testimonial from './s-carousel-item-testimonial';
import s_carousel_item_voucher_benefit from './s-carousel-item-voucher-benefit';

import s_header_slogan from './s-header-slogan';
import s_banner_content from './s-banner-content';

const COMPONENTS_CONTROLLERS = {
  'n-button': s_button,
  'n-faq': s_faq,
  'n-content': s_content,
  'n-headline': s_headline,
  'n-list': s_list,
  'n-picture': s_picture,
  'n-tile': s_tile,
  'n-video': s_video_embed,
  'n-event-grid': s_event_grid,
  'n-tabbed-event-grid': s_tabbed_event_grid,
  'n-filtered-event-grid': s_filtered_event_grid,
  'n-newsletter-banner': s_newsletter_banner,
  'n-logo-row': s_logo_row,
  's-general-banner': s_general_banner,
  'n-separator': s_separator,
  'n-merger': s_separator,
  'n-anchor': s_anchor,
  'n-html': s_html,
  'n-section-column': s_section_column,
  'carousel-general-layout': s_carousel_general_layout,
  'l-founder': s_founder,
  'l-reviews': s_reviews,
  'l-team-event': s_team_event,
  'voucher-header': s_voucher_header,
  'carousel-item-general-layout': s_carousel_item_general_layout,
  'carousel-item-testimonial': s_carousel_item_testimonial,
  'carousel-item-voucher-benefit': s_carousel_item_voucher_benefit,
  'carousel-item-voucher-category': s_carousel_item_voucher_category,
  's-header-slogan': s_header_slogan,
  's-banner-content': s_banner_content
};

export default function (component, context) {
  if (component) {
    try {
      const controller = COMPONENTS_CONTROLLERS[component.component];

      return controller(component, context);
    } catch (error) {
      console.log('🚀 ~ file: n-section-column.js ~ line 10 ~ parseComponent ~ error', error, component);
      return {};
    }
  }
  return {};
}
