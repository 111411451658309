import SSectionColumnController from '../components/s-section-column';

export default function ({ cssFlags, background, gridColumns, columns, _uid, _editable }, context) {
  return {
    module: 'SSection',
    _uid,
    _editable,
    gridColumns: parseInt(gridColumns, 10),
    cssFlags,
    background,
    columns: columns.map(c => SSectionColumnController(c, context))
  };
}
