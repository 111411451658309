//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, onMounted, onUnmounted, ref, useContext } from '@nuxtjs/composition-api';
import { formatGtm, themeGetters } from '@odyssey/realtainment';
import { getSyliusImage, PRODUCT_GIFTCARD_TYPE } from '@/helpers';
import 'photoswipe/dist/photoswipe.css';

export default {
  name: 'EventImage',
  components: {
    NuxtTransformImage: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/NuxtTransformImage')
  },
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {}
    },
    theme: {
      type: Object | undefined,
      required: false,
      default: () => null
    }
  },
  setup(props) {
    const lightbox = ref(null);
    const context = useContext();

    const isAcrylMaterialKit = computed(() => props.product?.slug === 'artnight-acryl-material-kit');
    const isSimpleProduct = computed(() => isAcrylMaterialKit.value || props.product?.slug === 'postkarten-set');

    const assetsFolder = computed(() => (isAcrylMaterialKit.value ? 'acryl-kit' : 'card-set'));
    const extension = computed(() => (isAcrylMaterialKit.value ? 'png' : 'jpeg'));

    const simpleProductImages = [
      getSyliusImage(props.product?.image, 628, 418, false, context),
      `/assets/artnight/material-kit/${assetsFolder.value}/image_one.${extension.value}`,
      `/assets/artnight/material-kit/${assetsFolder.value}/image_two.${extension.value}`,
      `/assets/artnight/material-kit/${assetsFolder.value}/image_three.${extension.value}`
    ];

    const galleryImages = computed(() =>
      props.theme
        ? themeGetters.getGalleryImages(context, props.theme)
        : ['artnight-jahreskarte-440eur', 'artnight-jahreskarte-240eur'].includes(props.product.slug)
        ? [
            {
              src:
                props.product.slug === 'artnight-jahreskarte-440eur'
                  ? 'https://a.storyblok.com/f/112921/1080x1080/128a6ac354/pdp_offline_jahreskarte_88.jpeg'
                  : 'https://a.storyblok.com/f/112921/1080x1080/aae1fc1dab/pdp_online_jahreskarte_48.jpeg',
              width: 600,
              height: 600
            }
          ]
        : [
            {
              src: getSyliusImage(props.product.image, 628, 418, false, context),
              width: 628,
              height: 418
            },
            {
              src: `/assets/artnight/material-kit/${assetsFolder.value}/image_one.${extension.value}`,
              width: 288,
              height: 420
            },
            {
              src: `/assets/artnight/material-kit/${assetsFolder.value}/image_two.${extension.value}`,
              width: 288,
              height: 420
            },
            {
              src: `/assets/artnight/material-kit/${assetsFolder.value}/image_three.${extension.value}`,
              width: 1202,
              height: 898
            }
          ]
    );

    const images = computed(() =>
      props.theme
        ? [themeGetters.getCoverImage(context, props.theme), ...themeGetters.getAdditionalImages(context, props.theme)]
        : ['artnight-jahreskarte-440eur', 'artnight-jahreskarte-240eur'].includes(props.product.slug)
        ? [
            props.product.slug === 'artnight-jahreskarte-440eur'
              ? 'https://a.storyblok.com/f/112921/1080x1080/128a6ac354/pdp_offline_jahreskarte_88.jpeg'
              : 'https://a.storyblok.com/f/112921/1080x1080/aae1fc1dab/pdp_online_jahreskarte_48.jpeg'
          ]
        : isSimpleProduct.value
        ? simpleProductImages
        : [getSyliusImage(props.product.image, 628, 418, false, context)]
    );

    onMounted(async () => {
      if (props.product) {
        const PhotoSwipeLightbox = await import('photoswipe/dist/photoswipe-lightbox.esm');
        lightbox.value = new PhotoSwipeLightbox.default({
          dataSource: galleryImages.value,
          children: 'a',
          pswpModule: () => import('photoswipe/dist/photoswipe.esm')
        });
        lightbox.value.init();
      }
    });

    onUnmounted(() => {
      lightbox.value?.destroy();
      lightbox.value = null;
    });

    function showImage(imageIndex) {
      if (imageIndex !== undefined && lightbox.value) lightbox.value.loadAndOpen(imageIndex);
    }

    return {
      images,
      formatGtm,
      themeGetters,
      showImage
    };
  }
};
