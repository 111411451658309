import merge from '~lodash.mergewith'
import * as CoreSdk from '~@sentry/core'
import { captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } from '~@sentry/browser'
import { extraErrorDataIntegration } from '~@sentry/integrations'
import { init, browserTracingIntegration } from '~@sentry/vue'

export { init }
export const SentrySdk = { ...CoreSdk, ...{ captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } }

/** @type {string[]} */
const DISABLED_INTEGRATION_KEYS = ["ReportingObserver"]

/**
 * @typedef {Parameters<typeof init>[0]} InitConfig
 * @param {import('@nuxt/types').Context} ctx
 * @return {Promise<InitConfig>}
 */
export function getConfig (ctx) {
  /** @type {InitConfig} */
  const config = {
    dsn:"https:\u002F\u002Fad88a44d16ff409f905e8aed7cdc0c96@o205792.ingest.sentry.io\u002F3453936",
    environment:"staging",
    replaysSessionSampleRate:0.01,
    replaysOnErrorSampleRate:0.1,
    ignoreErrors:[new RegExp("AbortError", ""),new RegExp("Network Error", ""),new RegExp("Loading chunk [0-9] failed", ""),new RegExp("Request aborted", ""),new RegExp("'Event.path' is deprecated", ""),new RegExp("Deprecation messages are stored in the devtools-frontend", ""),new RegExp("Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type", ""),new RegExp("ResizeObserver loop completed with undelivered notifications.", "")],
    tracesSampleRate:0.05,
  }

  /** @type {NonNullable<InitConfig>['integrations']} */
  const resolvedIntegrations = [
    extraErrorDataIntegration(),
  ]

  resolvedIntegrations.push(browserTracingIntegration({
    router: ctx.app.router,
    ...{"routeLabel":"name"},
    ...{},
  }))
  merge(config, {"trackComponents":true,"tracing":true,"tracingOptions":{"hooks":["mount","update"],"timeout":2000,"trackComponents":true}}, {"tracesSampleRate":0.05,"browserOptions":{}})

  config.integrations = (defaultIntegrations) => {
    return [
      ...defaultIntegrations.filter(integration => !DISABLED_INTEGRATION_KEYS.includes(integration.name)),
      ...resolvedIntegrations,
    ]
  }
  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
