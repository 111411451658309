import locale77304fa0 from '../../lang/de.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: true,
}
export const options = {
  vueI18n: {"fallbackLocale":"de","silentTranslationWarn":true,"numberFormats":{"en":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"},"currencyNoCents":{"style":"currency","currency":"EUR","currencyDisplay":"symbol","minimumFractionDigits":0,"maximumFractionDigits":0}},"de":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"},"currencyNoCents":{"style":"currency","currency":"EUR","currencyDisplay":"symbol","minimumFractionDigits":0,"maximumFractionDigits":0}}}},
  vueI18nLoader: false,
  locales: [{"code":"de","label":"German","file":"de.js","iso":"de","isoCode":"de-DE"}],
  defaultLocale: "de",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/var/www/packages/realtainment/theme/lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"vsf-locale","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "https://vsf-next.ournights-staging.rocks",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: false,
  pages: {"hosts/_slug":{"de":"/kuenstler/:slug","en":"/host/:slug"},"venues/_slug":{"de":"/venue/:slug","en":"/venue/:slug"},"products":{"de":"/products","en":"/products"},"events":{"de":"/events","en":"/events"},"press":{"de":"/presse","en":"/press"},"rebooking":{"de":"/ticketumbuchung","en":"/ticket-rebooking"},"resend-booking":{"de":"/buchungsbestaetigung-anfordern","en":"/resend-booking"}},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  currency: "EUR",
  country: "DE",
  countries: [{"name":"US","label":"United States","states":["California","Nevada"]},{"name":"AT","label":"Austria"},{"name":"DE","label":"Germany"},{"name":"NL","label":"Netherlands"}],
  currencies: [{"name":"EUR","label":"Euro"},{"name":"USD","label":"Dollar"}],
  normalizedLocales: [{"code":"de","label":"German","file":"de.js","iso":"de","isoCode":"de-DE"}],
  localeCodes: ["de"],
}

export const localeMessages = {
  'de.js': () => Promise.resolve(locale77304fa0),
}
