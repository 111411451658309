import SCarouselItemVoucherCategory from './s-carousel-item-voucher-category';
import SCarouselItemGeneralLayout from './s-carousel-item-general-layout';
import SCarouselItemTestimonial from './s-carousel-item-testimonial';
import SCarouselItemVoucherBenefit from './s-carousel-item-voucher-benefit';

export default function ({
  _editable,
  _uid,
  slides,
  visibleSlidesDesktop,
  visibleSlidesMobile,
  navigation,
  overFlow,
  alignItem,
  defaultIndex,
  showAll,
  title,
  showAllText,
  link,
  zoomEffect,
  textAlign,
  verticalSlidesMobile,
  visibleSlidesTablet,
  maxWidth,
  imageHeightTablet,
  imageHeightMobile,
  imageHeightDesktop,
  fontSizeMobile,
  fontSizeTablet,
  fontSizeDesktop,
  id
}) {
  function getVSFComponent(component) {
    switch (component) {
      case 'carousel-item-general-layout':
        return SCarouselItemGeneralLayout(component);
      case 'carousel-item-voucher-category':
        return SCarouselItemVoucherCategory(component);
      case 'carousel-item-testimonial':
        return SCarouselItemTestimonial(component);
      case 'carousel-item-voucher-benefit':
        return SCarouselItemVoucherBenefit(component);
    }
  }
  return {
    module: 'SCarousel',
    _uid,
    _editable,

    products: slides.map((slide) => {
      slide.components = getVSFComponent(slide.component);
      return { ...slide, image: slide.image?.filename };
    }),

    visibleSlidesDesktop,
    visibleSlidesMobile,
    visibleSlidesTablet,
    navigation,
    overFlow,
    alignItem,
    defaultIndex: parseInt(defaultIndex),
    showAll,
    title,
    showAllText,
    link,
    zoomEffect,
    textAlign,
    verticalSlidesMobile,
    maxWidth,
    imageHeightTablet,
    imageHeightMobile,
    imageHeightDesktop,
    fontSizeMobile,
    fontSizeTablet,
    fontSizeDesktop,
    id
  };
}
