import StoryblokClient from '@odyssey/realtainment-api/src/storyblok/client';

export default function (options, context) {
  return {
    module: 'SNewsLetterBanner',
    _uid: options._uid,
    _editable: options._editable,
    cssFlags: options.cssFlags,
    formId: options.form_id,
    title: options.title,
    description: StoryblokClient(context)?.richTextResolver?.render(options.description),
    backgroundColor: options.background_color?.color,
    showCitySelect: options.show_city_select,
    hideImage: options.hide_image,
    tagContext: options.tag_context
  };
}
