import SLayoutRowController from '../components/s-layout-row';
import SHeroController from '../components/s-hero';

export default function (
  { hero, rows, _uid, _editable, title, allowIndexing, addNofollow, description, pageNotification },
  context
) {
  let parsedHero = undefined;

  function getVSFComponents(component) {
    switch (component) {
      case 'n-hero':
        return SHeroController(hero[0], context);
    }
  }
  if (hero?.length) {
    parsedHero = getVSFComponents(hero[0].component);
  }

  return {
    _uid,
    _editable,
    rows: rows?.map((row) => SLayoutRowController(row, context)) || [],
    hero: parsedHero,
    title,
    description,
    allowIndexing: allowIndexing === true,
    addNofollow: addNofollow === true,
    notification: pageNotification
  };
}
