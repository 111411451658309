export default function ({ _uid, _editable, image, title, text }) {
  return {
    module: 'SCarouselItemVoucherBenefit',
    _uid,
    _editable,
    title,
    image: image?.filename,
    text
  };
}
