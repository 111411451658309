import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext, Logger } from '@odyssey/core';
import { removeDuplicatesByKey, prepareFilterArgument } from '../helpers';
export * from './getters';

export const useHost = (): any => {
  const { $pimcore } = useVSFContext();
  const host = sharedRef(null, 'useHost--host');
  const data = sharedRef([], 'useHost--data');
  const loading = sharedRef(null, 'useHost--loading');
  const error = sharedRef(null, 'useHost--error');

  const addToData = (hosts, key = 'uuid') => {
    data.value = removeDuplicatesByKey([...data.value, ...hosts], key);
  };

  const searchOne = async (hostId: number): Promise<void> => {
    Logger.debug('useHost/searchOne', hostId);
    try {
      loading.value = true;
      host.value = await $pimcore.api.getHost({ hostId });
      addToData(host.value);
      error.value = null;
    } catch (err) {
      error.value = err;
      Logger.error('useHost/searchOne', err);
    } finally {
      loading.value = false;
    }
  };

  const search = async (hostUuid: string | Array<string>): Promise<void> => {
    if (hostUuid === null) return;
    const filter = prepareFilterArgument('userUuid', hostUuid, data.value);

    try {
      loading.value = true;
      const host = await $pimcore.api.getHosts({ filter });
      addToData(host);
      error.value = null;
    } catch (err) {
      error.value = err;
      Logger.error('useHost/search', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    search,
    searchOne,
    addToData,
    loading,
    error,
    result: computed(() => data.value),
    host: computed(() => host.value)
  };
};
