import { Logger } from '@odyssey/core';

async function addToCart({ context, $vsf, route }) {
  try {
    const query = await $vsf.$sylius.api.getProduct({
      page: 1,
      limit: 1,
      skus: [route.query.sku]
    }, {
      productsQuery: 'productsQuery'
    });

    let product = null;
    if (query.products.length > 0) {
      product = query.products[0];
      // check if there is enough stock
      if (parseInt(product.quantity) < parseInt(route.query.qty.toString())) {
        throw new Error(`Not enough stock for product with sku ${route.query.sku}. On stock: ${product.quantity}, Add to cart: ${route.query.qty}.`);
      }

      // user auth token
      const token = '';
      const apiState = $vsf.$sylius.config.state;
      let cartId = apiState.getCartId();
      if (!cartId) {
        cartId = await $vsf.$rest.api.createCart();
        apiState.setCartId(cartId);
      }

      const cartItem = {
        sku: product.sku,
        quoteId: cartId,
        qty: parseInt(route.query.qty)
      };

      await $vsf.$rest.api.updateCart({
        cartId,
        token,
        product: {
          cartItem
        }
      });

      context.redirect(302, route.query.sxs.toString());
    } else {
      throw new Error(`[Add to cart via URL] Product with sku ${route.query.sku} doesn't exist.`);
    }
  } catch (error) {
    Logger.error(error);
    context.redirect(302, route.query.fail.toString());
  }
}

export default async ({ app: { context }, $vsf, route }) => {
  if (!process.server || !route) return;
  if (route?.query?.sku && route?.query?.qty && route?.query?.sxs && route?.query?.fail) {
    await addToCart({ context, route, $vsf });
  }
};

