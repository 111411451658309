const middleware = {}

middleware['add-to-cart'] = require('../middleware/add-to-cart.js')
middleware['add-to-cart'] = middleware['add-to-cart'].default || middleware['add-to-cart']

middleware['checkout'] = require('../middleware/checkout.js')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['client-cache-control'] = require('../middleware/client-cache-control.js')
middleware['client-cache-control'] = middleware['client-cache-control'].default || middleware['client-cache-control']

middleware['group-events-redirect'] = require('../middleware/group-events-redirect.js')
middleware['group-events-redirect'] = middleware['group-events-redirect'].default || middleware['group-events-redirect']

middleware['is-authenticated'] = require('../middleware/is-authenticated.js')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

middleware['redirects'] = require('../middleware/redirects.js')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['refresh-auth'] = require('../middleware/refresh-auth.js')
middleware['refresh-auth'] = middleware['refresh-auth'].default || middleware['refresh-auth']

middleware['trailing-slash'] = require('../middleware/trailing-slash.js')
middleware['trailing-slash'] = middleware['trailing-slash'].default || middleware['trailing-slash']

export default middleware
