export default function ({ localVideo, externalVideo, previewImage, _uid, _editable }) {
  const src = String(localVideo?.filename).length ? localVideo.filename : externalVideo;
  const thumbnailSrc = previewImage?.filename ?? '';
  return {
    module: 'SVideoEmbed',
    _uid,
    _editable,
    src: src ?? '',
    thumbnailSrc,
    external: String(externalVideo).length > 0
  };
}
