import { joinURL, parseURL } from 'ufo';
const pimcoreUrl = 'https://assets.artnight.com';

export function getImage(src, { modifiers, baseURL = pimcoreUrl} = {}, { options, $img, nuxtContext}) {
  const { width, height, format, fit, smart, quality, providerModifiers } = modifiers;
  const doResize = width !== '0' || height !== '0';

  const ipxProvider = $img.options.provider;

  const defaultPimcoreOptions = options.providers.pimcore.defaults;

  const formatedOptions = joinURL(
    fit ? `fit_${fit}` : `fit_${defaultPimcoreOptions.fit}`,
    doResize ? `s_${width}x${height ?? width}` : '',
    smart ? 'smart' : '',
    quality ? `q_${quality}` : `q_${defaultPimcoreOptions.quality}`,
    format ? `f_${format}` : `f_${defaultPimcoreOptions.format}`
  );

  const { pathname } = parseURL(src);

  return {
    url: joinURL(
      `/_${ipxProvider}/`,
      formatedOptions.replace(/\//g, ','), '/',
      baseURL,
      pathname
    ),
    static: false
  };
}

