export default function ({ _uid, _editable, category: { categoryId }, showProducts = 6, showTitle }) {
  // const categoryData = store.getters['category/getCategories']?.find(c => c.id === categoryId)
  // store.dispatch('category-next/loadCategoryProducts', { route: router.currentRoute, category: categoryData, pageSize: showProducts })
  return {
    module: 'SEventGrid',
    _uid,
    _editable,
    // category: categoryData,
    fetchProducts: parseInt(showProducts, 10),
    showTitle
  };
}
