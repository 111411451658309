import { computed, reactive, ComputedRef } from '@nuxtjs/composition-api';
import { useVSFContext } from '@odyssey/core';
import { formatGtm } from '@odyssey/realtainment';

type GlobalUIElement = 'SideNav' | 'CityModal' | 'FiltersModal';

export const GlobalUIElements: Record<string, GlobalUIElement> = {
  SideNav: 'SideNav',
  CityModal: 'CityModal',
  FiltersModal: 'FiltersModal'
};

interface useGlobalUIState {
  activeElements: GlobalUIElement[];
}

const state: useGlobalUIState = reactive({
  activeElements: []
});

export const useGlobalUI = (): Record<string, any> => {
  const { $gtm, route } = useVSFContext();
  function toggleGlobalUI(element: GlobalUIElement) {
    if (state.activeElements.includes(element)) {
      state.activeElements = state.activeElements.filter(e => e !== element);
    } else {
      state.activeElements.push(element);
      if (element === GlobalUIElements.CityModal) {
        $gtm.push(formatGtm('listing/CITY_PANEL_OPEN', {
          location: route.value.meta.pageType
        }));
      }
    }
  }

  function setGlobalUI(element: GlobalUIElement, isActive: boolean): void {
    if (isActive) {
      state.activeElements.push(element);
    } else {
      state.activeElements = state.activeElements.filter(e => e !== element);
    }
  }

  function isGlobalUIActive(element: GlobalUIElement): ComputedRef<boolean> {
    return computed(() => state.activeElements.includes(element));
  }

  return {
    toggleGlobalUI,
    setGlobalUI,
    isGlobalUIActive
  };
};
