
export default function ({ _editable, _uid, title, text, image, ctaText, ctaLink }) {
  return {
    module: 'SFounder',
    _uid,
    _editable,
    title,
    text,
    image: image?.filename,
    ctaText,
    ctaLink
  };
}
