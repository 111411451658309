//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, useContext } from '@nuxtjs/composition-api';
import { useCategory } from '@odyssey/realtainment';

export default {
  name: 'Error',
  props: ['error'],
  components: {
    STabbedEventGrid: () =>
      import(/* webpackChunkName: "chunk-page-category" */ '@/components/ProductListing/STabbedEventGrid')
  },
  setup() {
    const { $config } = useContext();
    const { categories } = useCategory();
    const brand = $config.seo.defaultTitle;
    const category = computed(() => categories.value.find((c) => c.slug === 'berlin'));

    return {
      brand,
      category
    };
  }
};
