import { sharedRef, useVSFContext, Logger } from '@odyssey/core';
import { computed, reactive } from '@nuxtjs/composition-api';
import { NewsletterForm } from '@odyssey/realtainment-api';
import { validate as veeValidate } from 'vee-validate';
import { ValidationResult } from 'vee-validate/dist/types/types';
import { formatGtm } from '../helpers';
interface NewsletterErrors {
  city_id: ValidationResult | null;
  city_name: ValidationResult | null;
  email_address: ValidationResult | null;
}

export const useNewsletter = (): any => {
  const context = useVSFContext();
  const { $rest, $gtm } = context;
  const data = sharedRef(null, 'useNewsletter--data');
  const loading = sharedRef(null, 'useNewsletter--loading');
  const error = sharedRef(null, 'useNewsletter--error');

  const stateErrors = reactive({
    city_id: null,
    email_address: null
  } as NewsletterErrors);

  const subscribe = async (form: NewsletterForm, tagContext: string) => {
    try {
      loading.value = true;
      data.value = await $rest.api.subscribe({ form });
      error.value = null;

      $gtm.push(
        formatGtm('all/NEWSLETTER_SIGN_UP', {
          location: tagContext,
          email: form.email_address,
          city_name: form.city_name,
          city_id: form.city_id,
          source: tagContext,
          campaign: form.campaign
        })
      );
    } catch (err) {
      error.value = err;
      Logger.error('useNewsletter/subscribe', err);
    } finally {
      loading.value = false;
    }
  };

  const validate = async (form: NewsletterForm, tagContext: string): Promise<boolean> => {
    stateErrors.city_id = await veeValidate(form?.city_id, 'required');
    stateErrors.email_address = await veeValidate(form?.email_address, 'required|email');

    if (!stateErrors.email_address.valid) {
      $gtm.push(
        formatGtm('all/NEWSLETTER_ERROR', {
          location: tagContext,
          errorMessage: 'E-mail is not valid'
        })
      );
    }

    if (!stateErrors.city_id.valid) {
      $gtm.push(
        formatGtm('all/NEWSLETTER_ERROR', {
          location: tagContext,
          errorMessage: 'City is empty'
        })
      );
    }

    return (
      stateErrors.city_id && stateErrors.city_id.valid && stateErrors.email_address && stateErrors.email_address.valid
    );
  };

  return {
    subscribe,
    validate,
    stateErrors,
    loading,
    error,
    isSubscribed: computed(() => data.value?.code === 200)
  };
};
