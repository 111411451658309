import { useVSFContext, sharedRef } from '@odyssey/core';
import { computed } from '@nuxtjs/composition-api';
import { fromUnixTime, format, parse, isBefore, addDays } from 'date-fns';
import de from 'date-fns/locale/de';
import { useHost, hostGetters } from '../useHost';
import { formatGtm } from '../helpers';
import { useThemeCategory, themeCategoryGetters } from '../useThemeCategory';
import { Host, Theme, ThemeCategory } from '@odyssey/realtainment-api';
import { useCategory } from '../useCategory';

export const FILTER_SKILL = 'variants_skill';
export const FILTER_HOST = 'variants_hostUuid';
export const FILTER_THEME = 'variants_themeUuid';
export const FILTER_THEME_CATEGORY = 'variants_themeCategories_uuid';
export const FILTER_DATE = 'variants_eventTimestamp';
export const FILTER_CITY = 'variants_city';

const FILTERS_KEYS = [FILTER_SKILL, FILTER_THEME_CATEGORY, FILTER_THEME, FILTER_HOST, FILTER_DATE, FILTER_CITY];

type Filter = {
  id: string;
  image: string;
  name: string;
  count: number;
};

export const useProductFilters = (): any => {
  const context = useVSFContext();
  const { $config, $sylius, i18n, query, $gtm } = context;
  const { result: hosts, search: searchHosts } = useHost();
  const { result: themeCategories, search: searchThemeCategories } = useThemeCategory();
  const data = sharedRef({}, 'useProductFilters--data');
  const getURLFriendlyFilterKey = (filterKey) => {
    return filterKey.replace('variants_', '');
  };

  const SKILL_NAMES = {
    1: i18n.t('category.filters.skill.beginner'),
    2: i18n.t('category.filters.skill.intermediate'),
    3: i18n.t('category.filters.skill.advanced')
  };

  const { categories } = useCategory();

  const values = FILTERS_KEYS.reduce((map, key) => {
    const isOpen = false;
    let selected = [];
    let values = [];
    const value = query.value[getURLFriendlyFilterKey(key)];
    if (value) {
      selected = Array.isArray(value) ? value : [value];
      values = Array.isArray(value) ? value : [value];
    }
    map[key] = { isOpen, values, selected };
    return map;
  }, {});

  const filterValues = sharedRef(values, 'useProductFilters--filterValues');

  function getFilterOptions(
    options: Array<any>,
    entityCache: Array<Host | Theme | ThemeCategory>,
    getters: any
  ): Array<Filter> {
    return options
      .filter((f) => {
        return String(f.value.trim()).length > 0;
      })
      .map((f) => {
        const { value, productCount: count } = f;
        let image = '';
        let name = value;
        const entityObject = entityCache?.find(({ uuid }) => uuid === value);
        if (entityObject) {
          name = getters.getName(entityObject);
          image = getters.getImage(context, entityObject);
        }

        return { id: value, name, count, image };
      });
  }

  const search = async (params) => {
    try {
      let syliusFilters = await $sylius.api.getFilters(params);
      if (Array.isArray(syliusFilters)) {
        await Promise.all(
          syliusFilters.map(async (filter) => {
            const values = filter.options.map((o) => o.value);
            switch (filter.name) {
              case FILTER_HOST:
                await searchHosts(values);
                break;
              case FILTER_THEME_CATEGORY:
                await searchThemeCategories(values);
                break;
            }
          })
        );
        syliusFilters = [
          {
            name: FILTER_CITY,
            options:
              categories.value?.map(({ name, slug, productCount }) => ({
                id: slug,
                name,
                count: productCount
              })) || []
          },
          ...syliusFilters
        ];

        data.value = syliusFilters.reduce((map, obj) => {
          const name = obj.name;
          let options = obj.options;
          if (values[name]) {
            switch (name) {
              case FILTER_THEME_CATEGORY:
                options = getFilterOptions(options, themeCategories.value, themeCategoryGetters);
                break;
              case FILTER_HOST:
                options = getFilterOptions(options, hosts.value, hostGetters);
                break;
              case FILTER_DATE:
                options = options.map(({ value }) => ({
                  id: format(fromUnixTime(value), 'yyyy-MM-dd', { locale: de })
                }));
                break;
              case FILTER_SKILL:
                options = options
                  .filter(({ value }) => SKILL_NAMES[value])
                  .map(({ value: id, productCount: count }) => ({
                    id,
                    count,
                    name: SKILL_NAMES[id]
                  }));
                break;
            }
            map[name] = { name, options };
          }
          return map;
        }, {});
      }
    } catch (e) {
      console.error('useProductFilters/search', e);
    }
  };

  const selectFilter = (filterKey: string, value: Array<string>): void => {
    if (Array.isArray(value)) {
      filterValues.value[filterKey].selected = value;
      return;
    }
    filterValues.value[filterKey].selected = [];
  };

  const updateFilter = (filterKey: string, value: string): void => {
    const { values } = filterValues.value[filterKey];

    if (values.find((v) => v === value)) {
      filterValues.value[filterKey].values = values.filter((v) => v !== value);
      return;
    }
    filterValues.value[filterKey].values.push(value);
  };

  const resetFilter = (filterKey: string): void => {
    filterValues.value[filterKey].values = [];
    filterValues.value[filterKey].selected = [];
  };

  const toggleFilter = (filterKey: string): void => {
    for (const key in filterValues.value) {
      if (key !== filterKey) filterValues.value[key].isOpen = false;
    }
    if (filterValues.value[filterKey]) {
      filterValues.value[filterKey].isOpen = !filterValues.value[filterKey].isOpen;

      if (filterValues.value[filterKey].isOpen) {
        $gtm.push(
          formatGtm('listing/FILTER_OPEN', {
            filter: i18n.t('category.filters.filter.' + filterKey)
          })
        );
      }
    }
  };

  const getFilterName = (filterKey, city) => {
    switch (filterKey) {
      case FILTER_DATE:
        return i18n.t('category.filters.filter.date');
      case FILTER_SKILL:
        return i18n.t('category.filters.filter.skill');
      case 'type':
        return i18n.t('category.filters.filter.type');
      case FILTER_HOST:
        return i18n.t('category.filters.filter.host');
      case FILTER_THEME_CATEGORY:
        return i18n.t('category.filters.filter.theme_category');
      case FILTER_CITY:
        return city ? city : i18n.t('common.city');
    }
  };

  const getURLFilters = (categorySlug, urlFilters) => {
    const filters = {
      taxon: categorySlug ? categorySlug?.replace('-', '_') : null,
      hostUuids: urlFilters.filters?.hostUuid || null,
      themeUuids: urlFilters.filters?.themeUuid || null,
      themeCategoriesUuids: urlFilters.filters?.themeCategories_uuid || null,
      skills: urlFilters.filters?.skill?.map((s) => Number.parseInt(s)) || null,
      dates: urlFilters.filters?.eventTimestamp || null
    };

    if (String(urlFilters.start).length && String(urlFilters.end).length) {
      let parsedStart = parse(urlFilters.start, 'dd.MM.yyyy', new Date());
      const parsedEnd = parse(urlFilters.end, 'dd.MM.yyyy', new Date());

      filters.dates = [];
      while (isBefore(parsedStart, parsedEnd)) {
        filters.dates.push(format(parsedStart, 'dd.MM.yyyy'));
        parsedStart = addDays(parsedStart, 1);
      }
    }

    Object.keys(filters).forEach((filterKey) => {
      const filter = filters[filterKey];
      if (Array.isArray(filter) && filter.length === 0) {
        filters[filterKey] = null;
      }
    });
    return filters;
  };

  return {
    search,
    activeFilters: computed(() => filterValues.value),
    filters: computed(() => data.value),
    toggleFilter,
    updateFilter,
    selectFilter,
    resetFilter,
    getFilterName,
    getURLFilters,
    getURLFriendlyFilterKey,
    SKILL_NAMES
  };
};
