import { GTMReturn, GTMInput } from '../types';

// cannot find this tag in GTM
export const footerClick = (payload: GTMInput): GTMReturn => ({
  event: 'footer_click',
  eventInfo: { detail: 'click on a footer link' },
  category: { primaryCategory: 'interaction' },
  attributes: {
    location: payload.location,
    label: payload.label
  }
});

export const pageLoad = (payload: GTMInput): GTMReturn => ({
  event: 'page_load',
  page: {
    info: payload.info,
    category: payload.category,
    attributes: payload.attributes,
    _clear: true
  }
});

export const pageError = (payload: GTMInput): GTMReturn => ({
  event: 'page_error',
  page: {
    info: payload.info,
    category: payload.category,
    attributes: payload.attributes,
    _clear: true
  }
});

export const promotionImpression = (payload: GTMInput): GTMReturn => ({
  event: 'promotion_impression',
  eventInfo: {
    detail: 'view a promotional banner'
  },
  category: {
    primaryCategory: 'ecommerce'
  },
  attributes: {
    location: payload.location_id
  },
  items: typeof payload.items !== 'undefined' ? payload.items : [payload],
  _clear: true
});

export const promotionSelect = (payload: GTMInput): GTMReturn => ({
  event: 'promotion_select',
  eventInfo: {
    detail: 'click a promotional banner'
  },
  category: {
    primaryCategory: 'ecommerce'
  },
  attributes: {
    location: payload.location_id
  },
  items: [payload],
  _clear: true
});

// cannot find this tag in gtm
export const promotionDismiss = (payload: GTMInput): GTMReturn => ({
  event: 'promotion_dismiss',
  eventInfo: {
    detail: 'dismiss a promotional banner'
  },
  category: {
    primaryCategory: 'ecommerce'
  },
  attributes: {
    location: payload.location_id
  },
  items: [payload],
  _clear: true
});

export const miniCartOpen = (payload: GTMInput): GTMReturn => ({
  event: 'minicart_open',
  eventInfo: {
    detail: 'click on the mini cart to view products'
  },
  category: {
    primaryCategory: 'ecommerce'
  },
  attributes: {
    location: payload.location,
    currency: payload.currency
  },
  items: payload.items,
  _clear: true
});

export const newsletterError = (payload: GTMInput): GTMReturn => ({
  event: 'newsletter_error',
  eventInfo: {
    detail: 'error signing up to newsletter'
  },
  category: {
    primaryCategory: 'error'
  },
  attributes: {
    errorMessage: payload.errorMessage,
    location: payload.location
  }
});

export const newsletterSingUp = (payload: GTMInput): GTMReturn => ({
  event: 'newsletter_sign_up',
  eventInfo: {
    detail: 'newsletter sign up submission'
  },
  category: {
    primaryCategory: 'conversion'
  },
  attributes: {
    location: payload.location,
    email: payload.email,
    city_name: payload.city_name,
    city_id: payload.city_id,
    source: payload.source,
    date: `${new Date().toISOString().split('T')[0]} ${
      new Date()
        .toISOString()
        .replace(/:\d+.\d+Z$/g, '')
        .split('T')[1]
    }`,
    campaign: payload.campaign
  }
});

export const continueShopping = (): GTMReturn => ({
  event: 'continue_shopping',
  eventInfo: {
    detail: 'click on continue shopping in navigation'
  },
  category: {
    primaryCategory: 'interaction'
  }
});

export const proceedToPurchase = (): GTMReturn => ({
  event: 'proceed_to_purchase',
  eventInfo: {
    detail: 'click on proceed to purchase in navigation'
  },
  category: {
    primaryCategory: 'interaction'
  }
});
