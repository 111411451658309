import { mapGtm } from './gtm/map';
import { Context } from '@odyssey/core';
export const formatGtm = (key: string, payload: any) => mapGtm[key](payload);

export const removeDuplicatesByKey = (array: Array<any>, key: string): Array<any> => {
  return [...new Map(array.map((item) => [item[key], item])).values()];
};

export const prepareFilterArgument = (field: string, value: string | Array<string>, cache: Array<any>): string => {
  let filter = null;
  if (Array.isArray(value)) {
    // filter out items if we already fetched them before
    value = value.filter((uuid) => !cache.find((c) => c[field] === uuid) && uuid !== '');
    if (value.length) {
      filter = JSON.stringify({
        $or: value.map((val) => {
          const singleFilter = {};
          singleFilter[field] = val;
          return singleFilter;
        })
      });
    }
  } else {
    const cached = cache.find((c) => c[field] === value);
    if (!cached && value !== '') {
      const singleFilter = {};
      singleFilter[field] = value;
      filter = JSON.stringify(singleFilter);
    }
  }

  return filter;
};

export const hack__replaceJpegWithJpg = (filename: string): string => {
  return filename?.replace('.jpeg', '.jpg');
};

export const slugify = (string: string): string => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};
