//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, ref, useContext } from '@nuxtjs/composition-api';
import { isProductDisabled } from '@/helpers';
export default {
  name: 'AddToCart',
  components: {
    SButton: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-buttons" */ '@/components/UI/molecules/buttons/SButton'),
    Select: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-selects" */ '@/components/UI/molecules/selects/Select')
  },
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    theme: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const { $config } = useContext();

    const kidsEventCategoryId = $config.theme.kids_category;
    const themeCategories = computed(() => props.product?.themeCategoryUuid);
    const isKidsEvent = themeCategories.value?.includes(kidsEventCategoryId);

    const isSimpleProduct = computed(() => props.product?.typeId === 'material_kit');

    const simpleProductQuantity = 25;
    const quantity = ref(isKidsEvent ? 2 : 1);

    const qtySelectOptions = computed(() => {
      let c = isKidsEvent ? 1 : 0;
      const maxQty = isSimpleProduct.value ? simpleProductQuantity : props.product.quantity;
      return [...new Array(maxQty)].map(() => {
        c++;
        return {
          value: c.toString(),
          label: c.toString()
        };
      });
    });

    function clickHandler() {
      emit('addToCart', {
        ...props.product,
        qty: quantity.value
      });
    }

    function updateQuantity(qty) {
      quantity.value = parseInt(qty);
    }

    return {
      quantity,
      qtySelectOptions,
      disabled: computed(() => isProductDisabled(props.product)),
      clickHandler,
      updateQuantity,
      isSimpleProduct,
      isKidsEvent
    };
  }
};
