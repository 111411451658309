import { reactive, computed } from '@nuxtjs/composition-api';
import { useVSFContext } from '@odyssey/core';
import { formatGtm, useCart, useGTM } from '@odyssey/realtainment';

const state = reactive({
  isCartSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isLoginModalOpen: false,
  isNewsletterModalOpen: false,
  isProductTicketListOpen: false,
  isCategoryGridView: true,
  isFilterSidebarOpen: false,
  isMobileMenuOpen: false
});

const useUiState = () => {
  const { prepareGTagItems } = useGTM();
  const context = useVSFContext();
  const { $gtm, route, i18n } = context;
  const { cart } = useCart();

  const socialLinks = [
    {
      name: 'Facebook',
      icon: 'fb.svg',
      url: 'https://www.facebook.com/ArtNight/'
    },
    {
      name: 'Instagram',
      icon: 'ig.svg',
      url: 'https://www.instagram.com/artnightevents/'
    },
    {
      name: 'TikTok',
      icon: 'tiktok.svg',
      url: 'https://www.tiktok.com/@artnightevents'
    },
    {
      name: 'YouTube',
      icon: 'yt.svg',
      url: 'https://www.youtube.com/channel/UCeCRgX6bWt38S1DOekrMLoA'
    },
    {
      name: 'Pinterest',
      icon: 'pt.svg',
      url: 'https://www.pinterest.de/artnight/'
    }
  ];

  const isMobileMenuOpen = computed(() => state.isMobileMenuOpen);
  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
  };

  const isCartSidebarOpen = computed(() => state.isCartSidebarOpen);

  const openCartSidebar = () => {
    state.isCartSidebarOpen = true;
    $gtm.push(formatGtm('all/MINICART_OPEN', {
      currency: i18n.numberFormats[i18n.locale].currency.currency,
      location: route.value.meta.pageType,
      items: cart.value?.items?.map((item: any) => prepareGTagItems(context, {
        ...item.product,
        qty: item.qty
      })) || []
    }));
  };

  const closeCartSidebar = () => {
    state.isCartSidebarOpen = false;
  };

  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const isProductTicketListOpen = computed(() => state.isProductTicketListOpen);
  const toggleProductTicketList = () => {
    state.isProductTicketListOpen = !state.isProductTicketListOpen;
  };

  const isWishlistSidebarOpen = computed(() => state.isWishlistSidebarOpen);
  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const isLoginModalOpen = computed(() => state.isLoginModalOpen);
  const toggleLoginModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isLoginModalOpen = !state.isLoginModalOpen;
  };

  const isNewsletterModalOpen = computed(() => state.isNewsletterModalOpen);
  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  const isCategoryGridView = computed(() => state.isCategoryGridView);
  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
  };

  const isFilterSidebarOpen = computed(() => state.isFilterSidebarOpen);
  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  return {
    socialLinks,
    isCartSidebarOpen,
    isWishlistSidebarOpen,
    isLoginModalOpen,
    isNewsletterModalOpen,
    isCategoryGridView,
    isFilterSidebarOpen,
    isMobileMenuOpen,
    isProductTicketListOpen,
    openCartSidebar,
    closeCartSidebar,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleLoginModal,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    toggleFilterSidebar,
    toggleMobileMenu,
    toggleProductTicketList
  };
};

export default useUiState;
