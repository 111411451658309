import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext, Logger } from '@odyssey/core';
import { removeDuplicatesByKey, prepareFilterArgument } from '../helpers';
export * from './getters';
export const useTheme = (): any => {
  const { $pimcore } = useVSFContext();
  const data = sharedRef([], 'useTheme--data');
  const techniques = sharedRef([], 'useTheme--techniques');
  const loading = sharedRef(null, 'useTheme--loading');
  const error = sharedRef(null, 'useTheme--error');

  const addToData = (themes, key = 'uuid') => {
    data.value = removeDuplicatesByKey([...data.value, ...themes], key);
  };

  const searchById = async (ids: string): Promise<void> => {
    try {
      loading.value = true;
      const theme = await $pimcore.api.getThemes({ ids });
      addToData(theme);
      error.value = null;
    } catch (err) {
      error.value = err;
      Logger.error('useTheme/searchById', err);
    } finally {
      loading.value = false;
    }
  };

  const searchByName = async (themeName: string): Promise<void> => {
    const filter = prepareFilterArgument('themeName', themeName, data.value);
    if (filter === null) return;

    try {
      loading.value = true;
      const theme = await $pimcore.api.getThemes({ filter });
      addToData(theme);
      error.value = null;
    } catch (err) {
      error.value = err;
      Logger.error('useTheme/search', err);
    } finally {
      loading.value = false;
    }
  };

  const search = async (themeUuid: string | Array<string>): Promise<void> => {
    const filter = prepareFilterArgument('uuid', themeUuid, data.value);
    if (filter === null) return;

    try {
      loading.value = true;
      const theme = await $pimcore.api.getThemes({ filter });
      addToData(theme);
      error.value = null;
    } catch (err) {
      error.value = err;
      Logger.error('useTheme/search', err);
    } finally {
      loading.value = false;
    }
  };

  const fetchTechniques = async (): Promise<void> => {
    // Techniques already fetched
    if (techniques.value.length > 0) return;
    try {
      techniques.value = await $pimcore.api.getTechniques();
    } catch (err) {
      Logger.error('useTheme/fetchTechniques', err);
    }
  };

  return {
    fetchTechniques,
    search,
    searchByName,
    searchById,
    addToData,
    loading,
    error,
    result: computed(() => data.value),
    techniques: computed(() => techniques.value)
  };
};
