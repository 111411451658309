import { sharedRef, useVSFContext, Logger } from '@odyssey/core';
import { computed } from '@nuxtjs/composition-api';
import { useTheme, useHost, useVenue } from '../index';
import { removeDuplicatesByKey, prepareFilterArgument } from '../helpers';

interface eventDataObject {
  theme: Record<string, any>
  host: Record<string, any>
  venue: Record<string, any>
  event: Record<string, any>
}

export const useEvent = (id = ''): any => {
  const { $pimcore } = useVSFContext();
  const data = sharedRef([], 'useEvent--events' + id);
  const loading = sharedRef(null, 'useEvent--loading' + id);
  const error = sharedRef(null, 'useEvent--error' + id);

  const { result: themes, search: searchTheme } = useTheme();
  const { result: hosts, search: searchHost } = useHost();
  const { result: venues, search: searchVenue } = useVenue();

  const addToData = (events, key = 'uuid') => {
    data.value = removeDuplicatesByKey([...data.value, ...events], key);
  };

  const search = async (eventKey: string | Array<string>): Promise<void> => {
    const filter = prepareFilterArgument('uuid', eventKey, data.value);
    if (filter === null) return;
    try {
      loading.value = true;
      const events = await $pimcore.api.getEvents({ filter });
      addToData(events);
      error.value = null;
    } catch (err) {
      error.value = err;
      Logger.error('useEvent/search', err);
    } finally {
      loading.value = false;
    }
  };

  const searchData = async (filters: Record<string, string>): Promise<any> => {
    let eventData = {} as eventDataObject;
    try {
      loading.value = true;

      const promises = [];
      if (filters.filter_theme) promises.push(searchTheme(filters.filter_theme));
      if (filters.filter_host) promises.push(searchHost(filters.filter_host));
      if (filters.filter_venue) promises.push(searchVenue(filters.filter_venue));
      if (filters.filter_event) promises.push(search(filters.filter_event));

      await Promise.all(promises);

      eventData = {
        theme: themes.value,
        host: hosts.value,
        venue: venues.value,
        event: data.value
      };

      error.value = null;
    } catch (err) {
      error.value = err;
      Logger.error('useEvent/searchData', err);
    } finally {
      loading.value = false;
    }

    return eventData;
  };

  return {
    search,
    searchData,
    addToData,
    loading,
    error,
    result: computed(() => data.value)
  };
};
