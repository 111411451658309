import SSectionController from './s-section';

export default function ({ cssFlags, hideOnBreakpoint, background, sections, _editable, _uid }, context) {
  return {
    module: 'SLayoutRow',
    _uid,
    _editable,
    cssFlags,
    background,
    hideOnBreakpoint,
    sections: sections.map(s => SSectionController(s, context))
  };
}
