import {
  Context,
  useProductFactory,
  ProductsSearchParams,
  UseProductFactoryParams,
  Logger
} from '@odyssey/core';

export type Product = Record<string, unknown>;

export type ProductsResponse = {
  data: Product[];
  total: number;
};
const params: UseProductFactoryParams<ProductsResponse, any> = {
  productsSearch: async (context: Context, params: ProductsSearchParams): Promise<ProductsResponse> => {
    const { customQuery, ...searchParams } = params;
    try {
      return await context.$sylius.api.getProduct(searchParams, customQuery);
    } catch (e) {
      Logger.error(e);
    }
  }
};

export const useProduct = useProductFactory<ProductsResponse, any>(params);
