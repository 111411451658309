//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { useContext } from '@nuxtjs/composition-api';
import { hostGetters } from '@odyssey/realtainment';

export default {
  name: 'EventHost',
  components: {
    NuxtTransformImage: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/NuxtTransformImage')
  },
  props: {
    host: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const context = useContext();

    return {
      hostGetters,
      context
    };
  }
};
