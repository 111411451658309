export default function ({
  _editable,
  _uid,
  title,
  link,
  image,
  motiveAmount,
  category,
  zoomEffect,
  pricePrefix,
  price,
  highlightedText,
  bulletIcon,
  textDescription,
  id
}) {
  return {
    module: 'SCarouselItemGeneralLayout',
    _uid,
    _editable,
    title,
    link,
    image,
    motiveAmount,
    category,
    zoomEffect,
    pricePrefix,
    price,
    highlightedText,
    bulletIcon,
    textDescription,
    id
  };
}
